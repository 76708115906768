
@import "../../../Import/SCSS/classes";

.MainNavigation.Horizontal
{
    @include no-select;

    display: inline-block;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;

    .NavigationChildren
    {
        @include shadow-wide;
        @include slide-down;

        position: relative;
        color: $color-white;
        background-color: $color-dark;

        @include adjust
        {
            $p: 15px * $adj;
            top: calc(100% + #{$p});
            min-width: 308px * $adj;
            padding: $p 0;
        }

        .NavigationItem
        {
            @include font;

            white-space: nowrap;

            @include adjust
            {
                padding: (10px * $adj) (15px * $adj);
                font-size: 17px * $adj;
            }
        }

        .NavigationItemLink:focus,
        .NavigationItemLink.ActiveAncestor,
        .NavigationItemLink.ActiveLink
        {
            text-decoration: underline;
        }
    }

    .NavigationChildrenItems
    {
        @include slide-down-short;
        position: relative;
    }

    .NavigationChildrenWrapper
    {
        $p: 40px;
        position: absolute;
        left: -15px;
        margin: 0 (-$p) (-$p) (-$p);
        padding: 0 $p $p $p;
        overflow: hidden;
    }

    .NavigationColumn
    {
        display: inline-block;
        vertical-align: top;
    }

    .NavigationColumn + .NavigationColumn
    {
        @include adjust
        {
            margin-left: 15px * $adj;
            padding-left: 15px * $adj;
        }
    }

    .NavigationItemLinkLabel
    {
        display: inline;
    }

    & > .NavigationItems > .NavigationItem
    {
        @include font-alt-1;

        display: inline-block;
        position: relative;
        vertical-align: middle;
        white-space: nowrap;
        cursor: pointer;

        @include adjust
        {
            height: 32px * $adj;
            margin: 0 (20px * $adj);
            font-size: 17px * $adj;
            line-height: 32px * $adj;

            @media screen and (min-width: 1401px) and (max-width: 1500px) {
                margin: 0 15px;
                font-size: 16px;
            }
            @media screen and (min-width: 1001px) and (max-width: 1400px) {
                margin: 0 10px;
                font-size: 12px;
            }
        }

        .Icon
        {
            transition: transform $duration-quick $timing-in-out;

            @include adjust
            {
                width: 32px * $adj;
                height: 32px * $adj;
            }
        }

        &.Expand .Icon
        {
            transform: rotate(180deg);
        }

        & > .NavigationItemLink
        {
            &:focus,
            &.ActiveAncestor,
            &.ActiveLink
            {
                text-decoration: underline;
                outline: none;
            }
        }

        @include hover
        {
            color: $color-beige;
        }
    }
}

.MainNavigation.Vertical
{
    @include no-select;

    .NavigationChildren
    {
        position: relative;

        @include adjust
        {
            padding: 0 (15px * $adj) (15px * $adj);
        }

        .NavigationItem
        {
            @include font;
            @include adjust
            {
                line-height: 36px * $adj;
            }
        }
    }

    .NavigationItemLink:focus
    {
        text-decoration: underline;
        outline: none;
    }

    & > .NavigationItems
    {
        position: relative;

        & > .NavigationItem
        {
            @include font-alt-1;

            position: relative;
            margin: 0 0 -1px;
            white-space: nowrap;
            cursor: pointer;
            border-top: 1px solid currentColor;
            border-bottom: 1px solid currentColor;

            @include adjust
            {
                font-size: 16px * $adj;
                line-height: 54px * $adj;
            }

            .Icon
            {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: transform $duration-quick $timing-in-out;

                @include adjust
                {
                    right: 12px * $adj;
                    width: 32px * $adj;
                    height: 32px * $adj;
                }
            }

            &.Expand
            {
                color: $color-black;
                background-color: $color-beige;
                z-index: 1;

                & > .NavigationItemLink
                {
                    border-color: $color-beige;
                }
            }

            &.Expand
            {
                z-index: 1;

                .Icon
                {
                    transform: translateY(-50%) rotate(180deg);
                }

                .NavigationItem
                {
                    @include slide-down-short;
                }

                @include stagger(".NavigationItem")
                {
                    animation-delay: $sm * $duration-stagger;
                }
            }

            & > .NavigationItemLink
            {
                display: block;
                position: relative;

                @include adjust
                {
                    padding: 0 15px * $adj;
                }
            }
        }
    }
}