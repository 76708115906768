
@import "../../../Import/SCSS/classes";

.Newsletter
{
    h4
    {
        @include heading-4-alt;

        @include adjust
        {
            margin-bottom: 24px * $adj;
        }
    }
}

.NewsletterConfirm
{
    @include fade-in;
    @include no-select;

    position: relative;
    text-align: center;
    margin: 0;

    h3
    {
        @include heading-5;

        @include adjust
        {
            margin-bottom: 16px * $adj;
        }
    }

    p
    {

        @include adjust
        {
            margin-top: -16px * $adj;
            font-size: 14px * $adj;
            line-height: 20px * $adj;
        }
    }

    .Button
    {
        @include adjust
        {
            margin-top: 32px * $adj;
        }
    }

    .Icon
    {
        @include adjust
        {
            width: 96px * $adj;
            height: 96px * $adj;
        }
    }
}

.NewsletterTray
{
    position: relative;
    text-align: justify;

    @include adjust
    {
        margin-top: 24px * $adj;
    }

    &:after
    {
        display: inline-block;
        width: 100%;
        content: "";
    }

    .NewsletterConfirm &
    {
        top: 4px;
        text-align: right;
    }
}

.NewsletterTrayLeft,
.NewsletterTrayRight
{
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.NewsletterTrayLeft
{
    @include adjust
    {
        width: calc(100% - #{106px * $adj});
    }
}

.NewsletterTrayRight
{
    @include adjust
    {
        width: 106px * $adj;
    }
}

@include responsive-width($w-narrower)
{
    .Newsletter
    {
        text-align: center;
    }

    .NewsletterTray
    {
        margin: 0;
    }

    .NewsletterTrayLeft,
    .NewsletterTrayRight
    {
        display: block;
        width: auto !important;
        margin-top: 16px;
    }
}