@import "../../../../Import/SCSS/classes";

.TemplateEateriesContainer
{
    position: relative;
    overflow: hidden;
    text-align: center;

    @include adjust
    {
        margin-top: $h-header * $adj;
        padding: (72px * $adj) (48px * $adj) 0;
    }

    h1
    {
        @include heading-5;
    }

    @include responsive-width($w-narrow)
    {
        margin-top: $h-header;
        margin-bottom: 60px;
    }

    @include responsive-width($w-narrower)
    {
        margin-top: $h-header-narrow;
        padding: (48px * $adj) (16px * $adj) 0;
    }
}

.TemplateEateriesFilter
{
    display: inline-block;
    position: relative;
    text-align: left;

    @include adjust
    {
        margin: (32px * $adj) 0;
        padding: 0 (-16px * $adj)
    }

    .Field
    {
        @include adjust
        {
            margin: (-12px * $adj) 0;
        }
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        margin: 32px;

        .Field
        {
            display: inline-block;
            width: 50%;
        }
    }
}

.TemplateEateriesFilterColumn
{
    display: inline-block;
    position: relative;
    vertical-align: top;

    @include adjust
    {
        margin: 0 (16px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        display: block;
    }
}

.TemplateEateriesItem
{
    @include fade-in;
    @include no-select;

    display: inline-block;
    width: 25%;
    box-sizing: border-box;
    vertical-align: top;

    @include adjust
    {
        padding: 12px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        width: 50%;
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;
    }
}

.TemplateEateriesItemContact
{
    color: $color-gray;

    @include adjust
    {
        min-height: 80px * $adj;
        font-size: 14px * $adj;
    }

    div
    {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.TemplateEateriesItemContactAddress
{
    @include adjust
    {
        margin-bottom: 8px * $adj;
    }
}

.TemplateEateriesItemContent
{
    color: $color-white;
    background-color: $color-black;

    @include adjust
    {
        padding: 12px * $adj;
    }
}

.TemplateEateriesItemLogoWrapper
{
    position: relative;
    height: 6vw;
    overflow: hidden;
    
    .LocalLogo
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        white-space: nowrap;

        @include adjust
        {
            font-size: 1.2vw * $adj;
        }

        &.CustomLogo
        {
            width: 70%;

            @include responsive-width($w-narrow)
            {
                width: 90%;
            }
        }
    }

    @include responsive-width($w-narrow)
    {
        height: 12vw;

        .LocalLogo
        {
            font-size: 16px;
        }
    }

    @include responsive-width($w-narrower)
    {
        height: 24vw;

        .LocalLogo
        {
            font-size: 16px;
        }
    }
}

.TemplateEateriesItemTray
{
    text-align: right;

    @include adjust
    {
        margin-top: 12px * $adj;
    }
}

.TemplateEateriesItems
{
    position: relative;

    @include adjust
    {
        margin: (32px * $adj) (-12px * $adj) (64px * $adj);
    }
}

.TemplateEateriesOnDisplay
{
    color: $color-gray;

    @include adjust
    {
        font-size: 14px * $adj;
    }
}