
@import "../../../Import/SCSS/classes";

.Dialog
{
    @include segment;
    @include slide-up;
    @include shadow;

    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    
    @include adjust
    {
        max-width: 948px * $adj;
        padding: (96px * $adj) (24px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: 72px 16px;

        &.FillNarrow
        {
            @include overlay(0, fixed);
            overflow: auto;
        }
    }

    &.HasHeader
    {
        padding-top: 160px !important;
        color: $color-black;
        background-color: $color-white;
        font-family: $font-family-alt-1;

        .DialogClose
        {
            color: $color-white;
        }
    }
}

.DialogAlign
{
    display: table-cell;
    position: relative;
    vertical-align: middle;

    @include adjust
    {
        padding: (40px * $adj) (48px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: 16px;
    }
}

.DialogBelow,
.DialogBelowNarrow
{
    position: relative;

    @include adjust
    {
        margin-top: 64px * $adj;
    }
}

.DialogBelow
{
    @include responsive-width($w-narrower)
    {
        display: none;
    }
}

.DialogBelowNarrow
{
    display: none;

    @include responsive-width($w-narrower)
    {
        display: block;
    }
}

.DialogButtons
{
    position: relative;

    .Button + .Button
    {
        margin-top: 8px * $adj;
    }
}

.DialogClose
{
    position: absolute !important;

    @include adjust
    {
        top: 16px * $adj;
        right: 16px * $adj;
        width: 32px * $adj;
        height: 32px * $adj;
    }
}

.DialogContainer
{
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
}

.DialogContent
{
    @include slide-up-short;
}

.DialogHeader
{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    color: $color-white;
    background-color: $color-black;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 120px;
    text-transform: uppercase;

    @include responsive-width($w-narrow)
    {
        font-size: 1em;
    }
}

.DialogTray
{
    position: absolute;
    
    @include adjust
    {
        right: 16px * $adj;
        bottom: 16px * $adj;
        font-size: 12px * $adj;
    }

    .Link
    {
        text-decoration: underline;

        &:focus
        {
            color: $color-gold;
        }
    }

    @include responsive-width($w-narrow)
    {
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: 16px;
        text-align: right;
    }
}

.DialogWrapper
{
    position: relative;
    overflow: hidden;

    @include adjust
    {
        padding: (10px * $adj) 0;
    }
}

.Dialogs
{
    @include overlay(0, fixed);

    z-index: 200000;
}

.DialogsOverlay
{
    //@include fade-in;
    @include overlay;

    backdrop-filter: blur(10px) brightness(60%);
}

.DialogsScroll
{
    @include overlay;

    overflow: auto;
}