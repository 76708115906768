
@import "../../../../Import/SCSS/classes";

.ModuleText
{
    .SegmentText
    {
        @include content;

        p
        {
            text-align: left;
        }
    }
}