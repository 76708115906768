
@import "../../../Import/SCSS/classes";

.OpenStatus
{
    @include heading-6;
    @include no-select;

    &.Status0
    {
        color: $color-red;
    }

    &.Status1,
    &.Status2,
    {
        color: $color-gold;
    }

    &.Status3
    {
        color: $color-green;
    }

    .Icon
    {
        transform: translateY(-2%);
        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }
}