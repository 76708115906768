
@import "../../../Import/SCSS/classes";

.Links
{

    &.Vertical
    {
        .LinksItem
        {
            display: block;
            width: auto;
            padding: 8px 0;

            &:first-child
            {
                padding-top: 0;
            }

            &:last-child
            {
                padding-bottom: 0;
            }
        }

        .LinksItemContent
        {
            height: auto;
            text-align: left;
        }

        .LinksItemLabel
        {
            position: relative;
            top: 0;
            right: 0;
            left: 0;
            padding: 16px 68px 16px 24px;
            transform: none;
        }

        .LinksItemMessage
        {
            position: relative;
            top: auto;
            right: auto;
            left: auto;
            padding-left: 24px;
            white-space: normal;
        }

        .LinksItems
        {
            margin: 0;
        }
    }

    @include responsive-width($w-narrow)
    {
        &.DoubleColumn
        {
            .LinksItem
            {
                padding: 0 1px 1px 0;
                font-size: 13px;
            }

            .LinksItemContent
            {
                height: 68px;

                .Icon
                {
                    display: none;
                }
            }

            .LinksItemLabel
            {
                right: 24px;
                left: 24px;
                white-space: normal;
            }

            .LinksItems
            {
                margin: 0 -1px -1px 0;
            }
        }

        &.SingleColumn
        {   
            .LinksItem
            {
                display: block;
                width: auto;
                padding: 8px 0;

                &:first-child
                {
                    padding-top: 0;
                }

                &:last-child
                {
                    padding-bottom: 0;
                }
            }

            .LinksItemContent
            {
                height: auto;
                text-align: left;
            }

            .LinksItemLabel
            {
                position: relative;
                top: 0;
                right: 0;
                left: 0;
                padding: 16px 68px 16px 24px;
                transform: none;
            }

            .LinksItems
            {
                margin: 0;
            }
        }

        .LinksItemMessage
        {
            position: relative;
            top: auto;
            right: auto;
            left: auto;
            padding-left: 24px;
            white-space: normal;

            @include responsive-width($w-narrower)
            {
                margin-top: 4px;
            }
        }
    }
}

.LinksItem
{
    @include button-behaviour;
    @include button-transition;
    @include font-alt-1;
    @include no-select;

    display: inline-block;
    position: relative;
    width: 25%;
    vertical-align: top;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
    outline: none;

    @include adjust
    {
        padding: 12px * $adj;
        font-size: 22px * $adj;
    }

    &:focus .LinksItemContent
    {
        @include button-focus;
    }

    @include hover-active
    {
        .LinksItemContent
        {
            @include button-hover-active;
        }
    }

    @include responsive-width($w-narrow)
    {
        width: 50%;
    }
}

.LinksItemContent
{
    @include button-colors;
    @include button-transition;
    
    position: relative;
    border-width: 2px;
    border-style: solid;
    overflow: hidden;

    @include adjust
    {
        height: 156px * $adj;
    }

    .Icon
    {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include adjust
        {
            right: 16px * $adj;
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }
}

.LinksItemLabel
{
    position: absolute;
    top: 50%;
    right: 32px * $adj;
    left: 16px * $adj;
    transform: translateY(-50%);
}

.LinksItemMessage
{
    @include font;

    position: absolute;
    top: 85%;
    transform: translateY(-50%);

    @include adjust
    {
        right: 16px * $adj;
        left: 16px * $adj;
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.LinksItems
{
    position: relative;
    margin: -12px;
    text-align: center;
}