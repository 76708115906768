
@import "../../../../Import/SCSS/classes";

.TimeSelectField
{
    .SelectFieldOption
    {
        @include adjust
        {
            padding: (2px * $adj) (8px * $adj) !important;
        }
    }
}

.TimeSelectFieldItem
{
    position: relative;
    white-space: nowrap;

    @include adjust
    {
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.TimeSelectFieldItemIcon,
.TimeSelectFieldItemInfo,
.TimeSelectFieldItemLabel
{
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.TimeSelectFieldItemIcon
{
    .Icon
    {
        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }
}

.TimeSelectFieldItemInfo
{
    box-sizing: border-box;
    white-space: nowrap;

    @include adjust
    {
        width: calc(59% - #{12px * $adj});
    }

    .Field
    {
        @include field-input-sub;
    }
}

.TimeSelectFieldItemLabel
{
    box-sizing: border-box;
    white-space: nowrap;

    @include adjust
    {
        width: calc(41% - #{12px * $adj});
        padding-left: 8px * $adj;
    }
}