
@import "../../../../Import/SCSS/classes";

.RepeatingFields
{
    @include field;

    label
    {
        @include field-label;
    }

    .DotNavigation
    {
        text-align: center;

        @include adjust
        {
            margin: (8px * $adj) 0 (16px * $adj);
        }
    }
}

.RepeatingFieldsItem
{
    position: relative;

    &.Focus
    {
        z-index: 99 !important;
    }
}

.RepeatingFieldsItemForm
{
    .FieldWrapper
    {
        &:first-child
        {
            margin-top: 0;
        }
    }
}