
@import "../../../Import/SCSS/classes";

.Tag
{
    @include no-select;
    @include text-overflow;
    
    display: inline-block;
    position: relative;
    color: $color-gray-dark;
    background-color: $color-beige;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    @include adjust
    {
        margin: 4px * $adj;
        padding: 0 (16px * $adj);
        border-radius: 8px * $adj;
        font-size: 10px * $adj;
        line-height: 24px * $adj;
    }
}