
@import "../../../../Import/SCSS/classes";

.TemplateAboutUsImage
{
    .LoadImage
    {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.TemplateAboutUsPromises
{
    @include adjust
    {
        padding: (96px * $adj) (16px * $adj);
    }

    h3
    {
        @include heading-3;

        text-align: center;
        text-transform: none;
    }
}

.TemplateAboutUsPromiseGroup
{
    @include content;

    @include adjust
    {
        margin: (72px * $adj) 0;
    }

    h3
    {
        @include font-alt-2;

        text-align: center;

        @include adjust
        {
            font-size: 36px * $adj;
            line-height: 36px * $adj;
        }

        .Icon
        {
            @include adjust
            {
                width: 36px * $adj;
                height: 36px * $adj;
            }
        }
    }
}

.TemplateAboutUsPromisesSelected
{
    @include segment;

    @include adjust
    {
        margin: (96px * $adj) 0;
        padding: 40px * $adj;
    }

    h3
    {
        @include heading-6;
    }

    h4
    {
        @include font-alt-2;

        @include adjust
        {
            margin: (40px * $adj) 0 (16px * $adj);
            font-size: 36px * $adj;
            line-height: 36px * $adj;
        }
    }

    p
    {
        margin: 0 auto;
        text-align: center !important;

        @include adjust
        {
            max-width: 300px * $adj;
            font-size: 14px * $adj;
            line-height: 20px * $adj;
        }
    }
}

.TemplateAboutUsTop
{
    h5
    {
        @include heading-5;

        @include adjust
        {
            margin-bottom: 40px * $adj;
        }
    }

    p
    {
        text-align: left;
    }
}

.TemplateAboutUsText
{
    @include content;
}