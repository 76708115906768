
@import "../../../Import/SCSS/classes";

.PriceListContent
{
    @include content;

    b,
    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
    {
        text-transform: uppercase;
    }

    i,
    em,
    i b,
    i strong,
    em b,
    em strong,
    b i,
    b em,
    strong i,
    strong em
    {
        text-transform: none !important;
    }

    h3,
    h4
    {
        font-size: 1.3em;
        line-height: 1.3;
    }

    h5,
    h6
    {
        font-size: 1em;
        line-height: 1;
    }

    p
    {
        max-width: 720px;
    }
}

.PriceListFooter
{
    position: relative;
    white-space: nowrap;

    @include adjust
    {
        margin-top: 72px * $adj;
    }
}

.PriceListFooterColumn
{
    display: inline-block;
    text-transform: uppercase;

    @include adjust
    {
        margin: 0 12px;
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.PriceListHeader
{
    @include no-select;
    @include adjust
    {
        margin-bottom: 72px * $adj;
    }

    h2
    {
        @include heading-5;
    }
}

.PriceListLogo
{
    display: block !important;
    
    @include adjust
    {
        margin: (72px * $adj) auto 0;
    }
}