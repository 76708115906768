
@import "../../../../Import/SCSS/classes";

.TextareaField
{
    @include field;

    label
    {
        @include field-label;
    }

    .Input
    {
        @include field-input;

        resize: none;
        box-sizing: initial;

        @include adjust
        {
            width: calc(100% - #{18px * $adj})
        }
    }
}