
@import "../../../../Import/SCSS/classes";

.TemplateReservationContent
{
    @include content;

    p
    {
        text-align: left;
    }
}

.TemplateReservationItem
{
    @include segment;

    display: block;
    position: relative;

    @include adjust
    {
        height: 240px * $adj;
    }

    .LocalLogo
    {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 0.75em;
        transform: translate(-50%, -50%);

        &.CustomLogo
        {
            width: 100%;
        }
    }

    &:focus
    {
        outline: 2px solid $color-gold;

        .TemplateReservationItemButton
        {
            @include button-focus;
        }
    }
}

.TemplateReservationItemButton
{
    @include button-appearance;
    @include button-small;
    @include button-transition;

    position: absolute;

    @include adjust
    {
        right: 16px * $adj;
        bottom: 16px * $adj;
    }
}

.TemplateReservationItemWrapper
{
    display: inline-block;
    position: relative;
    width: 50%;
    box-sizing: border-box;

    @include adjust
    {
        padding: 8px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;
    }
}

.TemplateReservationItems
{
    @include items-container;

    line-height: 0;
}

.TemplateReservationItemsWrapper
{
    @include items-wrapper;

    margin: 0 auto;

    @include adjust
    {
        max-width: 900px * $adj;
    }

    .Sundbyberg,
    .Gardenarlanda,
    .Gardenvoco,
    .Kistanod {
        svg {
            path {
                fill: #000000 !important;
            }
        }
    }

    .Button
    {
        @include adjust
        {
            margin-top: 96px * $adj;
        }
    }
}

.TemplateReservationTop
{
    @include content-segment;

    padding-bottom: 0 !important;

    h1
    {
        @include heading-5;
    }
}