
@import "../../../Import/SCSS/classes";

.ThreeColumns
{
    position: relative;

    @include adjust
    {
        padding: (96px * $adj) (36px * $adj);
    }

    @include responsive-width($w-narrow)
    {
        padding: 0 36px;

        &.ReverseNarrow
        {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    @include responsive-width($w-narrower)
    {
        padding: 48px 16px;
    }
}

.ThreeColumnsColumn
{
    display: inline-block;
    position: relative;
    min-height: 8px;
    vertical-align: top;
    text-align: center;
    box-sizing: border-box;

    @include adjust
    {
        padding: 0 (12px * $adj);
    }

    @include responsive-width($w-narrow)
    {
        display: block;
        width: auto !important;
        padding: 20px 12px;
        min-height: 0;
    }

    @include responsive-width($w-narrower)
    {
        padding: 16px 0;
    }
}

.ThreeColumnsCenter
{
    width: 50%;

    .ThreeColumnsContent
    {
        position: relative;
        margin: 0 auto;

        @include adjust
        {
            max-width: 593px * $adj;
        }
    }
}

.ThreeColumnsLeft,
.ThreeColumnsRight
{
    width: 25%;

    .ThreeColumnsContent
    {
        position: relative;
    }
}

.ThreeColumnsRight
{
    .ThreeColumnsContent
    {
        margin: 0 0 0 auto;
    }
}
