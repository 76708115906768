
@import "../../../../Import/SCSS/classes";

.ModuleFooter
{
    .SegmentText
    {
        @include content;
    }

    .ThreeColumns
    {
        @include adjust
        {
            padding-top: 20px * $adj !important;
        }
    }
}
