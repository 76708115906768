
@import "../../../../Import/SCSS/classes";

.TemplateDigitalMeetingsItems
{
    @include items-container;
}

.TemplateDigitalMeetingsItemsWrapper
{
    @include items-wrapper;

    h2
    {
        @include heading-3;

        text-align: center;
        text-transform: none;

        @include adjust
        {
            margin-bottom: 72px * $adj;
        }
    }
}

.TemplateDigitalMeetingsStaffItems
{
    @include items-container;
}

.TemplateDigitalMeetingsStaffItemsWrapper
{
    @include items-wrapper;
}