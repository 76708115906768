
@import "../../../../Import/SCSS/classes";

.CheckboxField
{
    text-align: left;
    vertical-align: top;
    
    .Icon
    {
        display: block;
        width: 100%;
        height: 100%;
    }

    .Input
    {
        @include button-behaviour;

        display: inline-block;
        position: relative;

        @include adjust
        {
            width: 48px * $adj;
            height: 48px * $adj;
            margin: 0 12px * -$adj;
        }
    }

    &:after
    {
        display: block;
        clear: both;
        content: "";
    }

    &.Focus
    {
        .Input
        {
            outline: none;
            color: $color-gold;
        }
    }
}

.CheckboxFieldLabel
{
    display: inline-block;
    position: relative;
    vertical-align: top;

    @include adjust
    {
        width: calc(100% - #{48px * $adj});
        padding: (15px * $adj) 0 (15px * $adj) (15px * $adj);
        font-size: 14px * $adj;
    }

    .Link
    {
        text-decoration: underline;

        &:focus
        {
            color: $color-gold;
        }
    }
}