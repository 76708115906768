
@import "../../../../Import/SCSS/classes";

.ModuleAltTop
{
    h1
    {
        @include font-alt-1;

        @include adjust
        {
            font-size: 48px * $adj;
            line-height: 48px * $adj;
        }
    }

    p
    {
        @include adjust
        {
            margin: (24px * $adj) 0 (120px * $adj);
            font-size: 20px * $adj;
            line-height: 28px * $adj;
        }
    }

    .FullContent
    {
        text-align: left;

        @include adjust
        {
            padding: (376px * $adj) (160px * $adj) (120px * $adj);
        }
    }

    .FullTable
    {
        width: 60% !important;
    }

    .MainLogo
    {
        position: absolute;
        width: 100%;
        z-index: 10;

        @include adjust
        {
            top: 120px * $adj;
            left: 160px * $adj;
            max-width: 336px * $adj;
        }
    }

    .SegmentBackgroundImage
    {
        left: 60% !important;
    }

    .SegmentButtons
    {
        @include adjust
        {
            margin: -8px * $adj;
        }

        .Button
        {
            display: inline-block;
            max-width: none;

            @include adjust
            {
                width: calc(50% - #{16px * $adj});
                margin: 8px * $adj;
            }
        }
    }

    @include responsive-width($w-narrow)
    {
        h1
        {
            font-size: 22px;
            line-height: 28px;
        }

        p
        {
            max-width: $w-content;
            margin: 24px auto 40px;
        }

        .Full,
        .SegmentBackgroundImage
        {
            padding-bottom: 106.67% !important;

            .FullTable
            {
                width: 100% !important;
                min-height: 0 !important;
            }
        }

        .FullContent
        {
            padding: 0 16px 72px;
            text-align: center;
        }

        .MainLogo
        {
            display: block;
            position: relative;
            max-width: 160px;
            margin: 32px auto 0;
            top: 0;
            left: 0;
        }

        .SegmentBackgroundImage
        {
            top: auto !important;
            left: 0 !important;
        }

        .SegmentButtons
        {
            margin: 0;

            .Button
            {
                display: block;
                width: auto;
                max-width: 360px;
                margin: 8px auto;
            }
        }
    }
}