
@use "sass:math";

@import "../../../Import/SCSS/classes";

.Footer
{
    position: relative;
    color: $color-white;

    @include adjust
    {
        padding: (96px * $adj) (48px * $adj);
    }

    .Newsletter
    {
        @include adjust
        {
            max-width: 438px * $adj;
        }
    }
}

.FooterColumn
{
    display: inline-block;
    position: relative;
    width: math.div(100%, 3);
    vertical-align: top;
    box-sizing: border-box;

    .FooterColumn + &
    {
        @include adjust
        {
            padding-top: 16px * $adj;
        }
    }

    &:first-child
    {
        @include adjust
        {
            padding-right: 32px * $adj;
        }
    }

}

.FooterCorner
{
    @include no-select;

    position: absolute;

    @include adjust
    {
        right: 48px * $adj;
        bottom: 16px * $adj;
    }

    .Link
    {
        color: $color-gray;

        @include adjust
        {
            margin-left: 24px * $adj;
            font-size: 14px * $adj;
        }

        &:focus,
        .ActiveLink
        {
            text-decoration: underline;
        }
    }
}

.FooterSocialMedia
{
    @include no-select;

    position: relative;
    
    h5
    {
        @include heading-5;
        
        @include adjust
        {
            margin-bottom: 24px * $adj;
        }
    }

    .Icon
    {
        @include adjust
        {
            width: 24px * $adj;
            height: 24px * $adj;
            margin-right: 16px * $adj;
        }
    }

    .Link
    {
        @include font-alt-1;

        display: block;

        @include adjust
        {
            font-size: 17px * $adj;
            line-height: 32px * $adj;
        }

        &:focus
        {
            text-decoration: underline;
            outline: none;
        }
    }
}

@include responsive-width($w-narrow)
{
    .Footer .Newsletter
    {
        max-width: none;
    }

    .FooterColumn
    {
        display: block;
        width: auto;
        padding: 0 0 32px !important;
    }

    .FooterCorner
    {
        right: auto;
        left: 48px;

        .Link
        {
            margin-left: 0;
            margin-right: 24px;
        }
    }

    .FooterNavigation
    {
        margin: 0 -48px;
    }

    .FooterSocialMedia
    {
        h5,
        span
        {
            display: none;
        }

        .Link
        {
            display: inline-block;

            &:focus .Icon
            {
                color: $color-gold;
            }
        }
    }
}

@include responsive-width($w-narrower)
{
    .Footer
    {
        padding: 40px 16px;
    }

    .FooterCorner
    {
        left: 16px;
    }

    .FooterNavigation
    {
        margin: 0 -16px;
    }
}