
@import "../../../Import/SCSS/classes";

.PostContent
{
    @include content;
}

.PostFooter
{
    position: relative;
    white-space: nowrap;

    @include adjust
    {
        margin-top: 72px * $adj;
    }
}

.PostFooterColumn
{
    display: inline-block;
    text-transform: uppercase;

    @include adjust
    {
        margin: 0 12px;
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.PostHeader
{
    @include no-select;
    @include adjust
    {
        margin-bottom: 72px * $adj;
    }

    h2
    {
        @include heading-5;
    }
}

.PostLogo
{
    display: block !important;
    
    @include adjust
    {
        margin: (72px * $adj) auto 0;
    }
}