
@import "../../../../Import/SCSS/classes";

.TextField
{
    @include field;

    label
    {
        @include field-label;
    }

    .Input
    {
        @include field-input;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button
    {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number]
    {
        -moz-appearance: textfield;
    }

    input[type=time]::-webkit-calendar-picker-indicator
    {
        background: none;
        display: none;
    }
}