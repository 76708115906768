
@import "../../../Import/SCSS/classes";

.Segment
{
    @include segment;
    
    position: relative;

    &.Top .SegmentContent
    {
        z-index: 1;
        
        @include adjust
        {
            padding-top: $h-header * $adj;
        }

        @include responsive-width($w-narrow)
        {
            padding-top: $h-header-narrow;
        }
    }
}
    

.SegmentContent
{
    position: relative;
}