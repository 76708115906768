
@import "../../../Import/SCSS/classes";

.ItemRoom
{
    @include fade-in;
    @include no-select;

    display: inline-block;
    width: 25%;
    box-sizing: border-box;
    vertical-align: top;
    
    @include adjust
    {
        padding: 12px * $adj;
    }

    h3
    {
        @include font-alt-1;
        
        margin: 0 !important;
        text-transform: capitalize;

        @include adjust
        {
            font-size: 18px * $adj !important;
            line-height: 24px * $adj !important;
        }
    }

    h5
    {
        position: relative;
        color: $color-gray;
        text-transform: uppercase;

        @include adjust
        {
            margin: (8px * $adj) 0 0;
            font-size: 12px * $adj;
            line-height: 16px * $adj;
        }
    }

    @include responsive-width($w-narrow)
    {
        width: 50%;
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;
    }
}

.ItemRoomButtons
{
    position: relative;
    text-align: right;
    //white-space: nowrap;

    @include adjust
    {
        font-size: 18px * $adj;
        line-height: 24px * $adj;
    }

    .Button
    {
        @include adjust
        {
            margin-left: 8px * $adj;
        }
    }
}

.ItemRoomContent
{
    position: relative;
    background-color: $color-white;
    text-align: center;

    @include adjust
    {
        padding: 16px * $adj;
    }
}

.ItemRoomImage
{
    position: relative;
    padding: 0 0 45.76659%;
    background-color: $color-dark;
    overflow: hidden;

    @include adjust
    {
        border-radius: (16px * $adj) (16px * $adj) 0 0;
    }

    .LoadImage
    {
        @include overlay;

        background-repeat: no-repeat;
        background-size: cover;
    }
}

.ItemRoomTop
{
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-white;

    @include adjust
    {
        padding: (4px * $adj) (24px * $adj) (4px * $adj) (16px * $adj);
        border-radius: 0 0 0 (16px * $adj);
        font-size: 12px * $adj;
        line-height: 32px * $adj;
    }

    .Icon
    {
        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }
}

.ItemRoomTags
{
    line-height: 0;
    
    @include adjust
    {
        height: 90px * $adj;
    }
}

.ItemRoomWrapper
{
    overflow: hidden;

    @include adjust
    {
        border-radius: 16px * $adj;
    }
}