
@import "../../../Import/SCSS/classes";

.EateryCard
{
    .CardLogo
    {
        position: relative;
        margin: 0 auto;
        
        @include adjust
        {
            width: 190px * $adj;
        }
    }
}

.EateryCardContent
{
    @include content;

    position: relative;

    @include adjust
    {
        max-width: 596px * $adj;
        margin: (72px * $adj) auto;
        padding: 0 (16px * $adj);
    }
    
    p
    {
        @include adjust
        {
            margin: (24px * $adj) 0;
            font-size: 17px * $adj;
            line-height: 24px * $adj;
        }
    }
}