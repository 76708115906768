
@import "../../../../Import/SCSS/classes";

.TemplateLocationsAbout
{
    @include content;
}

.TemplateLocationsItem
{
    display: inline-block;
    
    @include adjust
    {
        width: calc(50% - #{48px * $adj});
        margin: 24px * $adj;
    }

    .ItemRoomTop {
        background-color: black;
        color: white;
        font-size: 13px;
        right: 100%;
        white-space: nowrap;

        @include responsive-width($w-narrow)
        {
            right: 0;
            top: auto;
            bottom: 100%;
            border-radius: 16px 0 0 0;
        }
    }

    h3
    {
        @include heading-5;
    }

    h5
    {
        @include field-label;

        color: $color-gray;
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;
    }
}

.TemplateLocationsItemAbout
{
    position: relative;

    @include adjust
    {
        @include max-rows(6, 20px * $adj);

        margin-top: 40px * $adj;
        font-size: 14px * $adj;
        line-height: 20px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        @include max-rows(4, 20px * $adj);
        margin-top: 16px * $adj;
    }
}

.TemplateLocationsItems
{
    position: relative;

    @include adjust
    {
        margin: -24px * $adj;
    }
}

.TemplateLocationItemTags
{
    position: relative;
    line-height: 0;
}

.TemplateLocationsItemsWrapper
{
    @include items-wrapper;
}

.TemplateLocationsTop
{
    position: relative;
    text-align: center;

    @include adjust
    {
        padding: (72px * $adj) (48px * $adj) 0;
    }

    h1
    {
        @include heading-3;
        text-transform: none;

        @include adjust
        {
            margin: (48px * $adj) 0 (24px * $adj);
        }
    }

    h3
    {
        @include heading-5;

        @include adjust
        {
            margin: 0 auto (48px * $adj);
        }
    }

    @include responsive-width($w-narrower)
    {
        padding: (48px * $adj) (16px * $adj) 0;
    }
}

.TemplateLocationsTopNew
{
    @include top-logo;
}
