
@import "../../../Import/SCSS/classes";

.Activity
{
    h2
    {
        @include heading-5;
        text-transform: none;
    }
}

.ActivityAbout
{
    @include content;

    @include adjust
    {
        margin-top: 40px * $adj;
    }
}

.ActivityPrice
{
    @include field-label;

    color: $color-gray;

    @include adjust
    {
        margin: (8px * $adj) 0 0;
    }
}

.ActivityTags
{
    @include adjust
    {
        margin-top: 4px * $adj;
    }
}