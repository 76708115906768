
@import "../../../Import/SCSS/classes";

.BookingStart
{
    @include segment;

    @include adjust
    {
        padding: (32px * $adj) (16px * $adj);
    }

    h3
    {
        @include heading-6;

        @include adjust
        {
            margin-bottom: 32px * $adj;
        }
    }

    .Buttons
    {
        position: relative;
        text-align: right;

        @include adjust
        {
            margin-top: 32px * $adj;
        }

        .Button
        {
            margin: 0;
        }
    }

    @include responsive-width($w-narrow)
    {
        max-width: $w-content;
        margin: 0 auto;
        padding: 0;
    }
}

.BookingStartDesktop
{
    @include responsive-width($w-narrow)
    {
        display: none;
    }
}

.BookingStartFields
{
    position: relative;

    @include adjust
    {
        margin: 0 (-2px * $adj);
    }

    .FieldWrapper
    {
        display: inline-block;
        position: relative;
        width: 100%;
        box-sizing: border-box;

        @include adjust
        {
            margin: (8px * $adj) 0;
            padding: 0 (2px * $adj);
        }

        &.Half
        {
            width: 50%;
        }

        &.Right
        {
            .DateFieldCalendarWrapper
            {
                left: auto;
                right: 0;
            }
        }

        @include responsive-width($w-narrower)
        {
            z-index: auto !important;
        }
    }
}

.BookingStartSimplified
{
    @include button-behaviour;
    @include button-transition;
    @include font-alt-1;
    @include no-select;
    @include button-colors;
    @include button-transition;
    
    display: none;
    position: relative;
    border-width: 2px;
    border-style: solid;
    overflow: hidden;
    text-align: left;

    .Icon
    {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include adjust
        {
            right: 16px * $adj;
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }

    @include responsive-width($w-narrow)
    {
        display: block;
    }
}

.BookingStartSimplifiedLabel
{
    padding: 16px 68px 16px 24px;
}