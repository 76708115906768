
@import "../../../Import/SCSS/classes";

.FormNavigation
{
    position: relative;
}

.FormNavigationItem
{
    @include button-behaviour;
    @include button-colors(false);

    display: inline-block;
    position: relative;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;

    @include adjust
    {
        min-width: 150px * $adj;
        margin: 4px * $adj;
        border-radius: 8px * $adj;
        font-size: 12px * $adj;
        line-height: 22px * $adj;
    }

    &:focus
    {
        @include button-focus;
    }

    @include hover-active
    {
        @include button-hover-active;
    }

    &.Disabled
    {
        pointer-events: none;
    }

    @include responsive-width($w-narrow)
    {
        min-width: 40px;

        .FormNavigationItemCheck
        {
            left: 2px;
        }

        .FormNavigationItemLabel
        {
            display: none;
        }

        .FormNavigationItemLabelNarrow
        {
            display: block;
            position: relative;
        }

        &.Done .FormNavigationItemLabelNarrow
        {
            left: 8px;
        }
    }
}

.FormNavigationItemCheck
{
    position: absolute;

    @include adjust
    {
        top: 2px * $adj;
        left: 4px * $adj;
        width: 20px * $adj;
        height: 20px * $adj;
    }
}

.FormNavigationItemLabelNarrow
{
    display: none;
}