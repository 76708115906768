
@import "../../../../Import/SCSS/classes";

.DateField
{
    @include field;
    
    label
    {
        @include field-label;
    }

    .FieldIcon
    {
        @include field-icon;

        transition: transform $duration-quick ease-in-out;
    }

    .Input
    {
        @include button-behaviour;
        @include field-input;
        @include no-select;

        padding: 0 !important;
    }

    &.Expand
    {
        .FieldIcon
        {
            transform: rotate(180deg);
        }
    }

    &.Focus
    {
        outline: none;

        .Input
        {
            @include field-input-focus;
        }
    }

    &.AlignLeft .DateFieldCalendarWrapper
    {
        left: 0;
    }

    &.AlignRight .DateFieldCalendarWrapper
    {
        right: 0;
    }
}

.DateFieldCalendarContainer
{
    @include field-input-radius;
    @include slide-down;

    position: relative;
    color: $color-white;
    background-color: $color-dark;

    @include adjust
    {
        margin-top: 16px * $adj;
        padding: (8px * $adj) 0;
        font-size: 17px * $adj;
        line-height: 20px * $adj;
    }

    .Calendar
    {
        @include slide-down-short;

        @include responsive-width($w-narrower)
        {
            @include slide-up-short;
        }
    }

    @include responsive-width($w-narrower)
    {
        @include shadow-up;
        @include slide-up;

        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 24px 16px;
    }
}

.DateFieldCalendarWrapper
{
    position: absolute;
    top: 100%;
    width: 100%;
    overflow: hidden;

    @include adjust
    {
        min-width: 293px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        position: fixed;
        top: 0;
        bottom: 0;
        margin-top: 0;
        z-index: 1000;
    }
}

.DateFieldValue
{
    position: relative;
    text-align: center;
    text-transform: capitalize;

    @include adjust
    {
        padding: (24px * $adj) 0;
        font-size: 12px * $adj;
        line-height: 18px * $adj;
    }
}

.DateFieldValueDate
{
    @include adjust
    {
        margin: (4px * $adj) 0;
        font-size: 22px * $adj;
        line-height: 28px * $adj;
    }
}