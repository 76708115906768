
@use "sass:math";

@import "../../../Import/SCSS/classes";

.Calendar
{
    @include no-select;
    @include segment;

    position: relative;
    text-align: center;

    &:focus
    {
        outline: 2px solid $color-gold;
    }
}

.CalendarDate
{
    @include button-behaviour;
    
    display: inline-block;
    position: relative;
    width: math.div(100%, 7);
    padding-bottom: math.div(100%, 7);
    vertical-align: top;

    span
    {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: transparent;
        border-radius: 100%;
        font-weight: 500;
        text-align: center;
        transform: translate(-50%, -50%);

        @include adjust
        {
            width: 24px * $adj;
            font-size: 12px * $adj;
            line-height: 24px * $adj;
        }
    }

    &.Disabled span
    {
        opacity: 0.1 !important;
        pointer-events: none;
    }

    &.Outside span
    {
        opacity: 0.3843;
    }

    &.Today span
    {
        border-color: currentColor;
    }

    &.Selected span
    {
        color: $color-white;
        background-color: $color-gold;
        border-color: $color-gold;
    }
}

.CalendarDay
{
    display: inline-block;
    position: relative;
    width: math.div(100%, 7);
    vertical-align: top;

    @include adjust
    {
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.CalendarHeading
{
    position: relative;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;

    @include adjust
    {
        padding: (16px * $adj) 0 (24px * $adj);
        font-size: 12px * $adj;
        line-height: 12px * $adj;
    }

    .IconButton
    {
        position: absolute;
        top: 0;

        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
        }

        &.CalendarNext
        {
            right: 0;
        }

        &.CalendarPrevious
        {
            left: 0;
        }
    }
}