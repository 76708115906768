
@import "../../../../Import/SCSS/classes";

.TemplateTextPage
{
    @include content;

    position: relative;
    margin: 0 auto;
    background-color: $color-white;
    text-align: center;
    
    @include adjust
    {
        max-width: 1100px * $adj;
    }

    &:before
    {
        display: inline-block;
        position: relative;
        width: 0%;
        padding: 0 0 141.4%;
        vertical-align: top;
        content: "";
    }
}

.TemplateTextPageContent
{
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    vertical-align: top;

    @include adjust
    {
        max-width: 1100px * $adj;
        padding: 120px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        padding: 48px;
    }

    @include responsive-width($w-narrower)
    {
        padding: 48px 24px;
    }
}

.TemplateTextPageContainer
{
    @include adjust
    {
        padding: 72px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        padding: 48px;
    }

    @include responsive-width($w-narrower)
    {
        padding: 40px 16px;
    }
}