
@import "../../../../Import/SCSS/classes";

.ModuleGalleryContent
{
    position: relative;
    margin: 0 auto;
    text-align: center;

    h2
    {
        @include heading-3;
        text-transform: none;

        @include adjust
        {
            margin: 0 0 (40px * $adj);
        }
    }

    @include adjust
    {
        max-width: 900px * $adj;
        padding: (72px * $adj) (48px * $adj);
    }
}