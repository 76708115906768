
@import "../../../Import/SCSS/classes";

.Delivery
{
    h3
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin-bottom: 48px * $adj;
        }
    }

    .LinksItem
    {
        width: 50%;
    }
}