@import "../../../../Import/SCSS/classes";
@import url("https://use.typekit.net/ojp8rbf.css");

.TemplateMenuGarden
{
    position: relative;
}

.TemplateMenuGardenBackground
{
    @include overlay;
    background-size: cover;
    pointer-events: none;
}

.TemplateMenuGardenPage
{
    @include shadow;

    position: relative;
    margin: 0 auto;
    background-color: $color-white;
    font-family: $font-family-alt-1;
    text-align: left;
    overflow: hidden;

    color: #F5F1E5;
    
    @include adjust
    {
        max-width: 1100px * $adj;
    }

    &:before
    {
        display: inline-block;
        position: relative;
        width: 0;
        padding: 0 0 141.4%;
        vertical-align: top;
        content: ""
    }

    h2
    {
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        font-family: veneer-two, sans-serif;

        b, strong {
            font-weight: 400;
            font-style: normal;
        }

        @include adjust
        {
            margin: 0.45em 0 0;
            font-size: 40px * $adj;
        }
    }

    p
    {
        line-height: 1.2;
        font-family: 'Roboto', sans-serif;

        @include adjust
        {
            margin: 0.5em 0;
            font-size: 16px * $adj;
        }
    }

    @include responsive-width($w-narrow)
    {
        h1
        {
            @include adjust
            {
                font-size: 20px * $adj;
            }
        }

        h2, h3
        {
            @include adjust
            {
                font-size: 30px * $adj;
            }
        }

        h4, h5, h6
        {
            @include adjust
            {
                font-size: 16px * $adj;
            }
        }

        p
        {
            @include adjust
            {
                font-size: 12px * $adj;
            }
        }
    }

    @include responsive-width($w-narrower)
    {
        h2
        {
            @include adjust
            {
                font-size: 24px * $adj;
            }
        }
    }
}

.TemplateMenuGardenPageContainer
{
    @include adjust
    {
        padding: 72px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        padding: 48px;
    }

    @include responsive-width($w-narrower)
    {
        padding: 40px 16px;
    }
}

.TemplateMenuGardenPageContent
{
    display: inline-block;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    vertical-align: top;

    @include adjust
    {
        max-width: 1100px * $adj;
        padding: (230px * $adj) (120px * $adj) (120px * $adj);
    }

    @include responsive-width($w-narrow)
    {
        padding: (160px * $adj) (80px * $adj) (80px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: (145px * $adj) (20px * $adj) (30px * $adj);

        br
        {
            display: none;
        }
    }

    .TemplateMenuGardenPageContentText{
        padding-top: 2rem;
    }
}

.TemplateMenuGardenPageContentBottom
{
    position: absolute;
    right: 0;
    bottom: 3.5%;
    left: 0;

    h2
    {
        user-select: none;
    }
}

.TemplateMenuGardenPageContentBottomText
{
    display: inline-block;
    padding: 0.5em 2em;
    border: 1px solid currentColor;

    p
    {
        line-height: 1.6;
    }

    @include adjust
    {
        margin: 0 (20px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: 0.5em 1em;
        
        p
        {
            font-size: 8px;
        }
    }
}

.TemplateMenuGardenPageContentTop
{
    position: absolute;
    top: 4%;
    right: 0;
    left: 0;
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-between;


    @include adjust
    {
        max-width: 1100px * $adj;
        padding: 0 (120px * $adj);

    }

    @include responsive-width($w-narrow)
    {
        padding: 0 (80px * $adj);
        flex-direction: column;
        align-items: center;
        gap: 0;
        top: 3%;
    }

    @include responsive-width($w-narrower)
    {
        padding: 0 (20px * $adj);
    }

    .MainLogoGarden
    {
        width: 100%;
        max-width: 440px;

        @include responsive-width($w-narrow)
        {
            max-width: 220px;
        }


        svg
        {
            width: 100%;
            height: auto;
        }
    }
}

.TemplateMenuGardenPageContentTopInfo
{
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    white-space: nowrap;

    @include adjust
    {
        margin: 3rem 0 0;
        font-size: 28px * $adj;
    }


    @include responsive-width($w-narrow)
    {
        margin: 0.5em 0 0;
    }

    h2
    {
        margin-top: 0;
        font-size: 58px * $adj;

        @include responsive-width($w-narrow)
        {
            font-size: 52px * $adj;
        }

        @include responsive-width($w-narrower)
        {
            font-size: 45px * $adj;
        }

    }

    span
    {
        position: relative;
        padding: 0 0.6em;

        &:first-child
        {
            padding-left: 0;
        }

        &:last-child
        {
            padding-right: 0;
        }
    }

    span + span:before
    {
        display: block;
        position: absolute;
        top: 0.2em;
        bottom: 0.2em;
        left: 0;
        width: 0.1em;
        background-color: currentColor;
        content: "";
    }

    @include responsive-width($w-narrow)
    {
        @include adjust
        {
            font-size: 20px * $adj;
        }
    }

    @include responsive-width($w-narrower)
    {
        @include adjust
        {
            font-size: 16px * $adj;
        }
    }
}

.TemplateMenuGardenTray
{
    position: relative;

    @include adjust
    {
        margin-top: 72px * $adj;
        padding-bottom: 72px * $adj;
    }
}