
@import "../../../Import/SCSS/classes";

$s: 1.3em;
$m: 1em;

.Help
{
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: normal;
    z-index: 1000;

    &.HasLabel
    {
        .HelpText
        {
            position: absolute;
            right: 0;
            bottom: 100%;
            margin-left: 0;
            transform: none;
        }

        .HelpTextContainer
        {
            position: absolute;
            bottom: 100%;
            right: 0;
        }
    }
}

.HelpClose
{
    position: absolute !important;
    top: 0.6em;
    right: 0.4em;
    z-index: 1;

    svg
    {
        stroke-width: 2.5;
    }
}

.HelpIcon
{
    @include button-behaviour;
    @include no-select;
    @include font-alt-1;

    display: block;
    position: relative;
    width: $s;
    height: $s;
    color: $color-white;
    background-color: $color-dark;
    border-radius: $s;
    text-align: center;
    line-height: $s * .95;

    &:focus
    {
        outline: none;
        background-color: $color-gold;
    }
}

.HelpLabel
{
    @include button-behaviour;
    @include no-select;

    position: relative;
    top: -0.1em;
    text-decoration: underline;

    &:focus
    {
        outline: none;
    }
}

.HelpText
{
    position: relative;
    width: 100%;
    margin-bottom: $s * 0.5;
    margin-left: $s * 0.5;
    color: $color-dark;
    background-color: $color-white;
    text-align: center;
    white-space: normal;
    
    @include adjust
    {
        max-width: (150px * $adj);
        padding: (16px * $adj) (8px * $adj);
        font-size: 10px * $adj;
        border-radius: 0 (16px * $adj) (16px * $adj) (16px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        margin: ($s * 0.5) 0 0;
        border-radius: (16px * $adj) 0 (16px * $adj) (16px * $adj);
    }
}

.HelpTextBackdrop
{
    @include overlay(0, fixed);
}

.HelpTextContainer
{
    position: absolute;
    left: 100%;

    @include adjust
    {
        top: 4px * $adj;
        min-width: 166px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        top: 100%;
        left: auto;
        right: 0;
    }
}