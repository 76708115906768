
@import "../../../Import/SCSS/classes";

.MapAttribution
{
    @include no-select;

    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    opacity: 0.5;

    @include adjust
    {
        padding: (8px * $adj) 0;
        font-size: 14px * $adj;
    }

    .Icon
    {
        @include adjust
        {
            width: 16px * $adj;
            height: 16px * $adj;
            margin-left: 4px * $adj;
        }
    }
}

.MapContainer,
{
    @include overlay;

    overflow: hidden;

    /*a,
    .gmnoprint,
    .gm-style-cc
    {
        display: none !important;
    }*/
}

.MapElement
{
    @include overlay;
}