
@import "../../../../Import/SCSS/classes";

.TemplatePostContent
{
    @include content;

    text-align: left;

    @include adjust
    {
        margin-top: 40px * $adj;
    }
}

.TemplatePostEateryLink
{
    @include adjust
    {
        margin-top: 40px * $adj;
    }
}

.TemplatePostImage
{
    @include overlay;
    
    background-size: cover;
    background-repeat: no-repeat;
}

.TemplatePostImageContainer
{
    position: relative;
    padding: 0 0 50%;
}

.TemplatePostImageWrapper
{
    position: relative;
    
    @include adjust
    {
        max-width: 900px * $adj;
        margin: (96px * $adj) auto 0;
    }
}

.TemplatePostWrapper,
.TemplatePostWrapperBottom,
.TemplatePostWrapperTop
{
    @include content-segment;

    overflow: hidden;

    h1
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            max-width: $w-content * $adj;
            margin: (40px * $adj) auto 0;
        }
    }

    h2
    {
        @include heading-5;
    }
}

.TemplatePostWrapperTop
{
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;

    .SegmentButtons
    {
        &.ButtonsAfter
        {
            @include adjust
            {
                padding-bottom: 96px * $adj;
            }
        }

        &.ButtonsBefore
        {
            margin-top: 0;

            @include adjust
            {
                padding-bottom: 48px * $adj;
            }
        }

        .Button
        {
            @include adjust
            {
                max-width: 400px * $adj;
            }
        }
    }
}

.TemplatePostWrapperBottom
{
    margin-top: 0 !important;
    padding-top: 0 !important;
}