
@import "../../../Import/SCSS/classes";

.Reservation
{
    h3
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin-bottom: 48px * $adj;
        }
    }

    .LinksItem
    {
        width: 50%;
    }

    .LinksItemContent
    {
        text-align: center !important;

        .EateryLogo.Sundbyberg,
        .EateryLogo.Gardenarlanda,
        .EateryLogo.Gardenvoco,
        .EateryLogo.Kistanod {
            svg {
                height: 75px !important;
            }
        }

    }
}

.ReservationLogo
{
    @include font;

    color: $color-white;

    @include responsive-width($w-narrow)
    {
        display: inline-block;
        text-align: center;
    }
}