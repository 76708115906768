
@import "vars";
@import "mixins";

@mixin adjust
{
    $adj: 1 !global;
    @content;

    @each $width, $m in $break-points
    {
        @include responsive-width($width)
        {
            $adj: $m !global;
            @content;
        }
    }
}

@mixin button
{
    @include button-appearance;
    @include button-behaviour;
    @include button-transition;

    &:focus
    {
        @include button-focus;
    }

    @include hover-active
    {
        @include button-hover-active;
    }
}

@mixin button-appearance
{
    @include button-colors;
    @include font-alt-1;
    @include heading-uppercase;

    display: block;
    position: relative;
    margin: 0 auto;
    border-style: solid;
    border-width: 2px;
    text-align: center;
    text-decoration: none !important;
    white-space: nowrap;
    box-sizing: border-box;
    overflow: hidden;

    @include adjust
    {
        padding: (16px * $adj) (32px * $adj);
        max-width: 360px * $adj;
        border-radius: 46px * $adj;
        font-size: 14px * $adj;
        list-height: 16px * $adj;
    }

    &.Big
    {
        @include responsive-width($w-narrower)
        {
            max-width: none;
        }
    }

    &.Small
    {
        @include button-small;
    }

    .ButtonsRow > &
    {
        display: inline-block;
        width: 100%;
        
        @include adjust
        {
            margin: 0 (8px * $adj);
        }

        &:first-child
        {
            margin-left: 0;
        }

        &:last-child
        {
            margin-right: 0;
        }
    }

    .SubLabel
    {
        @include font-alt-2;

        display: inline-block;
        position: relative;
        left: 0.25em;
        font-size: 1.2386em;
        text-transform: lowercase;
        line-height: 0;
        transform: rotate(-10deg);
    }

    @include responsive-width($w-narrower)
    {
        font-size: 11px;
    }
}

@mixin button-behaviour
{
    @include no-select;
    cursor: pointer;
}

@mixin button-colors($styleDisabled:true)
{
    &.ColorBeige
    {
        color: $color-black;
        background-color: $color-beige;
        border-color: $color-beige;

        &.Invert
        {
            color: $color-black;
            background-color: $color-white;
            border-color: $color-white;
        }
    }

    &.ColorBlack
    {
        color: $color-white;
        background-color: $color-dark;
        border-color: $color-dark;

        &.Invert
        {
            color: $color-black;
            background-color: $color-white;
            border-color: $color-white;
        }
    }

    &.ColorGold
    {
        color: $color-white;
        background-color: $color-gold;
        border-color: $color-gold;

        &.Invert
        {
            color: $color-black;
            background-color: $color-white;
            border-color: $color-white;
        }
    }

    &.ColorWhite
    {
        color: $color-black;
        background-color: $color-white;
        border-color: $color-white;

        &.Invert
        {
            color: $color-white;
            background-color: $color-dark;
            border-color: $color-dark;
        }
    }

    &.ColorXmas
    {
        color: $color-white;
        background-color: $color-xmas;
        border-color: $color-xmas;

        &.Invert
        {
            color: $color-xmas;
            background-color: $color-white;
            border-color: $color-white;
        }
    }

    &.Hollow
    {
        border-color: currentColor;
    }

    @if $styleDisabled
    {
        &.Disabled
        {
            color: $color-gray !important;
            background-color: $color-gray-light !important;
            border-color: $color-gray-light !important;
            pointer-events: none;
        }
    }
}

@mixin button-focus
{
    outline: none;

    &.ColorBeige
    {
        border-color: $color-gold !important;
    }

    &.ColorBlack
    {
        border-color: $color-gold !important;
    }

    &.ColorGold
    {
        border-color: $color-white !important;
    }

    &.ColorWhite
    {
        border-color: $color-gold !important;
    }

    &.Disabled
    {
        border-color: $color-gray !important;
    }

    &.Hollow
    {
        border-color: $color-gold !important;
    }
}

@mixin button-hover-active
{
    &.ColorBeige
    {
        background-color: $color-beige-dark;
        border-color: $color-beige-dark;

        &.Invert
        {
            background-color: $color-white-dark;
            border-color: $color-white-dark;
        }
    }

    &.ColorBlack
    {
        background-color: $color-dark-light;
        border-color: $color-dark-light;

        &.Invert
        {
            background-color: $color-white-dark;
            border-color: $color-white-dark;
        }
    }

    &.ColorGold
    {
        background-color: $color-gold-dark;
        border-color: $color-gold-dark;

        &.Invert
        {
            background-color: $color-white-dark;
            border-color: $color-white-dark;
        }
    }

    &.ColorWhite
    {
        background-color: $color-white-dark;
        border-color: $color-white-dark;

        &.Invert
        {
            background-color: $color-dark-light;
            border-color: $color-dark-light;
        }
    }

    &.Hollow
    {
        &:before
        {
            @include overlay;
            
            display: block;
            background-color: currentColor;
            opacity: 0.11;
            content: "";
        }
    }
}

@mixin button-small
{
    display: inline-block;

    @include adjust
    {
        padding: (8px * $adj) (16px * $adj);
        min-width: 106px * $adj;
        border-radius: 30px * $adj;
        font-size: 12px * $adj;
        line-height: 14px * $adj;
    }
}

@mixin button-transition
{
    transition: all $duration-quicker $timing-in-out;
}

@mixin content
{
    blockquote, h1, h2, h3, h4, h5, h6, ol, p, q, ul, .ContentImage
    {
        @include adjust
        {
            max-width: $w-content * $adj;
            margin: (24px * $adj) auto;
        }
    }

    blockquote, q
    {
        @include font-alt-2;

        quotes: "”" "”" "’" "’";

        p
        {
            display: inline;
            margin: 0;
            max-width: none;
            font-size: inherit;
            line-height: inherit;
        }

        &:after
        {
            content: close-quote;
        }

        &:before
        {
            content: open-quote;
        }

        @include adjust
        {
            font-size: 36px * $adj;
            line-height: 36px * $adj;
        }
    }

    blockquote + h1,
    blockquote + h2,
    blockquote + h3,
    blockquote + h4,
    blockquote + h5,
    blockquote + h6,
    q + h1,
    q + h2,
    q + h3,
    q + h4,
    q + h5,
    q + h6
    {
        margin-top: -2em;
    }

    h1
    {
        @include heading-1;
    }

    h2
    {
        @include heading-2;
    }

    h3
    {
        @include heading-3;
    }

    h4
    {
        @include heading-4;
    }

    h5
    {
        @include heading-5;

        text-transform: none;
        letter-spacing: initial;
    }

    h6
    {
        position: relative;
        font-weight: 500;
        text-transform: uppercase !important;
        letter-spacing: 0.05em;

        @include adjust
        {
            font-size: 12px * $adj;
            line-height: 16px * $adj;
        }
    }

    a
    {
        text-decoration: underline;
    }

    li, p
    {
        @include adjust
        {
            font-size: 17px * $adj;
            line-height: 24px * $adj;
        }
    }

    li
    {
        position: relative;
        text-align: left;

        @include adjust
        {
            margin: (24px * $adj) 0;
            padding-left: 48px * $adj;
        }

        .ListCheck,
        .ListNumber
        {
            display: block;
            position: absolute;
            top: 0;
            left: 0.2em;
            width: 1.41em;
            height: 1.41em;
        }

        .ListNumber
        {
            @include font-alt-1;
            text-align: center;

            &:after
            {
                content: "."
            }
        }
    }

    blockquote, q, .VideoPlayerContainer
    {
        @include adjust
        {
            margin: (48px * $adj) auto;
        }
    }

    .ContentImage
    {
        display: block;
        position: relative;

        @include adjust
        {
            margin: (48px * $adj) auto;
        }
    }
}

@mixin content-segment
{
    position: relative;
    text-align: center;

    @include adjust
    {
        padding: (96px * $adj) (48px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: 64px 16px;
    }
}

@mixin empty
{
    @include fade-in;
    @include heading-5;

    text-transform: none;
}

@mixin fade-in($d: $duration-quick)
{
    animation: fadeIn #{$d} $timing-out both;
}

@mixin field
{
    position: relative;
    text-align: left;

    &.Disabled
    {
        .Input
        {
            @include no-select;
            pointer-events: none;
            opacity: .5;
        }
    }
}

@mixin field-icon
{
    position: absolute;
    right: 0;

    @include adjust
    {
        top: 2px * $adj;
        width: 32px * $adj;
        height: 32px * $adj;
    }
}

@mixin field-input
{
    @include button-colors;
    @include field-input-radius;

    display: block;
    position: relative;
    width: 100%;
    appearance: none;
    color: currentColor;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;

    @include adjust
    {
        padding: (8px * $adj) 0 (8px * $adj) (14px * $adj);
        font-size: 17px * $adj;
        line-height: 20px * $adj;
    }

    @include input-placeholder
    {
        color: currentColor;
        opacity: 0.5;
    }

    &:focus
    {
        @include field-input-focus;
    }
}

@mixin field-input-radius
{
    @include adjust
    {
        border-radius: 5px * $adj;
    }
}

@mixin field-input-focus
{
    @include button-focus;

    @include input-placeholder
    {
        opacity: 1;
    }
}

@mixin field-input-sub
{
    display: inline-block;
    position: relative;

    @include adjust
    {
        width: 48px * $adj;
        height: 24px * $adj;
        margin: 0 (4px * $adj);
    }

    .Input
    {
        @include no-select-restore;
        
        padding: 0;
        border-width: 1px;
        text-align: center;

        @include adjust
        {
            font-size: 12px * $adj;
        }
    }
}

@mixin field-label
{
    @include font;

    display: block;
    position: relative;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    white-space: nowrap;

    @include adjust
    {
        margin: 4px * $adj;
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }

    .LabelTag
    {
        @include field-label-tag;
    }
}

@mixin field-label-tag
{
    @include no-select;

    display: inline-block;
    position: relative;
    top: -.15em;
    background-color: $color-white;
    white-space: nowrap;
    vertical-align: middle;

    @include adjust
    {
        margin-left: 8px * $adj;
        padding: 0 (16px * $adj);
        font-size: 10px * $adj;
        line-height: 16px * $adj;
    }
}

@mixin font
{
    font-family: $font-family;
    font-weight: $font-weight;
}

@mixin font-alt-1
{
    font-family: $font-family-alt-1;
    font-weight: $font-weight-alt-1;

    em, i
    {
        @include font-alt-2;
    }
}

@mixin font-alt-2
{
    font-family: $font-family-alt-2;
    font-weight: $font-weight-alt-2;
    letter-spacing: normal;
}

@mixin heading-1
{
    @include font-alt-1;

    @include adjust
    {
        font-size: 70px * $adj;
        line-height: 75px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        max-width: 340px;
        margin: 0 auto;
        font-size: 36px;
        line-height: 40px;
    }
}

@mixin heading-1-alt
{
    @include font-alt-2;

    letter-spacing: 0;
    text-transform: none;
    transform: rotate(-10deg);

    @include adjust
    {
        font-size: 100px * $adj;
        line-height: 100px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        font-size: 54px;
        line-height: 54px;
    }
}

@mixin heading-2
{
    @include font-alt-1;

    @include adjust
    {
        font-size: 56px * $adj;
        line-height: 60px * $adj;
    }
}

@mixin heading-2-alt
{
    @include font-alt-2;

    letter-spacing: 0;
    text-transform: none;
    transform: rotate(-10deg);

    @include adjust
    {
        font-size: 60px * $adj;
        line-height: 60px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        font-size: 32px;
        line-height: 32px;
    }
}

@mixin heading-3
{
    @include font-alt-1;

    @include adjust
    {
        font-size: 22px * $adj;
        line-height: 28px * $adj;
    }
}

@mixin heading-3-alt
{
    @include font-alt-2;
    @include no-select;

    display: inline-block;
    position: relative;
    padding: 0.5em 0;
    transform: rotate(-10deg);

    @include adjust
    {
        font-size: 36px * $adj;
        line-height: 36px * $adj;
    }
}

@mixin heading-4
{
    @include heading-3;
}

@mixin heading-4-alt
{
    @include heading-3-alt;
}

@mixin heading-5
{
    @include font-alt-1;
    @include heading-uppercase;

    @include adjust
    {
        font-size: 17px * $adj;
    }
}

@mixin heading-5-alt
{
    @include font-alt-2;
    @include no-select;

    display: inline-block;
    position: relative;
    padding: 0.5em 0;
    transform: rotate(-10deg);

    @include adjust
    {
        font-size: 24px * $adj;
        line-height: 24px * $adj;
    }
}
@mixin heading-6
{
    @include heading-uppercase;
    
    font-weight: 500;

    @include adjust
    {
        margin: (24px * $adj) 0;
        font-size: 14px * $adj;
    }

    &:first-child
    {
        margin-top: 0;
    }

    &:last-child
    {
        margin-bottom: 0;
    }
}

@mixin heading-uppercase
{
    letter-spacing: 0.065em;
    text-transform: uppercase;
}

@mixin item-column
{
    white-space: nowrap;

    @include adjust
    {
        width: 96px * $adj;
    }
}

@mixin items-container
{
    position: relative;
    text-align: center;

    @include adjust
    {
        margin: -12px * $adj;
    }
}

@mixin items-wrapper
{
    position: relative;
    overflow: hidden;

    @include adjust
    {
        padding: (96px * $adj) (48px * $adj) (96px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: (72px * $adj) (16px * $adj) (72px * $adj);
    }
}

@mixin items-wrapper-staff
{
    position: relative;
    overflow: hidden;

    @include adjust
    {
        padding: (120px * $adj) (48px * $adj) 0;
    }

    @include responsive-width($w-narrower)
    {
        padding: (72px * $adj) (16px * $adj) 0;
    }
}


@mixin segment
{
    &.ColorBeige
    {
        color: $color-black;
        background-color: $color-beige;
    }

    &.ColorBlack
    {
        color: $color-white;
        background-color: $color-dark;
    }

    &.ColorWhite
    {
        color: $color-black;
        background-color: $color-white;
    }

    &.ColorTextBlack
    {
        color: $color-black;
    }

    &.ColorTextWhite
    {
        color: $color-white;
    }

    &.HasBackground
    {
        background-color: $color-dark;
    }

    .SegmentBackgroundImage
    {
        @include overlay;

        background-position: center center;
        background-size: cover;
    }

    .SegmentBackgroundVideo
    {
        @include overlay;
    }

    .SegmentButtons
    {
        @include adjust
        {
            margin-top: 96px * $adj;
        }

        .Button
        {
            @include adjust
            {
                margin-top: 16px * $adj;
            }
        }

        @include responsive-width($w-narrow)
        {
            margin-top: 36px;
        }
    }
}

@mixin shadow($m: 1)
{
    @include adjust
    {
        box-shadow: (3px * $m * $adj) (3px * $m * $adj) (10px * $m * $adj) color-black(.25);
    }
}

@mixin shadow-overlay($m: 1)
{
    @include adjust
    {
        box-shadow: (5px * $m * $adj) (5px * $m * $adj) (25px * $m * $adj) color-black(.25);
    }
}

@mixin shadow-overlay-text($m: 1)
{
    @include adjust
    {
        text-shadow: (5px * $m * $adj) (5px * $m * $adj) (25px * $m * $adj) color-black(.25);
    }
}

@mixin shadow-wide($m: 1)
{
    @include adjust
    {
        box-shadow: (3px * $m * $adj) (3px * $m * $adj) (40px * $m * $adj) color-black(.25);
    }
}

@mixin shadow-up($m: 1)
{
    @include adjust
    {
        box-shadow: (3px * $m * $adj) (-3px * $m * $adj) (40px * $m * $adj) color-black(.25);
    }
}

@mixin slide-down($d: $duration-quick)
{
    animation: slideDown #{$d} $timing-out both;
}

@mixin slide-down-short($d: $duration-quick)
{
    animation: slideDownShort #{$d} $timing-out both;
}

@mixin slide-left($d: $duration-quick)
{
    animation: slideLeft #{$d} $timing-out both;
}

@mixin slide-left-short($d: $duration-quick)
{
    animation: slideLeftShort #{$d} $timing-out both;
}

@mixin slide-right($d: $duration-quick)
{
    animation: slideRight #{$d} $timing-out both;
}

@mixin slide-right-short($d: $duration-quick)
{
    animation: slideRightShort #{$d} $timing-out both;
}

@mixin slide-up($d: $duration-quick)
{
    animation: slideUp #{$d} $timing-out both;
}

@mixin slide-up-short($d: $duration-quick)
{
    animation: slideUpShort #{$d} $timing-out both;
}

@mixin top-logo
{
    @include no-select;

    font-size: 2.32em;

    @include responsive-width($w-narrow)
    {
        font-size: 1.5em;
    }

    @include responsive-width(500px)
    {
        font-size: 1em;
    }
}