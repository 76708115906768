
@import "../../../../Import/SCSS/classes";

.FoodSelectFieldCategory
{
    @include text-overflow;

    color: $color-white;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .05em;

    @include adjust
    {
        margin: (8px * $adj) 0 (16px * $adj);
        font-size: 14px * $adj;
        line-height: 16px * $adj;
    }
}

.FoodSelectFieldItem
{
    position: relative;

    &:focus *
    {
        color: $color-gold !important;
    }
}

.FoodSelectFieldItemContent
{
    position: relative;
    overflow: hidden;

    @include adjust
    {
        padding: (6px * $adj) 0 0 (8px * $adj);
    }
}

.FoodSelectFieldItemDescription,
.FoodSelectFieldItemPrice
{
    color: $color-gray-light;

    @include adjust
    {
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.FoodSelectFieldItemPrice
{
    font-weight: 500;
    text-transform: uppercase;
}

.FoodSelectFieldItemIcon
{
    display: block;
    float: left;

    @include adjust
    {
        width: 32px * $adj;
        height: 32px * $adj;
    }
}

.FoodSelectFieldItemRight
{
    position: absolute;
    top: 0;
    color: $color-white;

    @include adjust
    {
        right: 8px * $adj;
    }

    .Field
    {
        @include field-input-sub;
    }

    .Icon
    {
        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }
}