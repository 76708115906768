
@import "../../../Import/SCSS/classes";

.Icon
{
    display: inline-block;
    position: relative;
    vertical-align: middle;

    svg
    {
        display: block;
        width: 100%;
        height: 100%;
    }
}