
@import "../../../../Import/SCSS/classes";

.SelectField
{
    @include field;

    &, *
    {
        outline: none;
    } 
    
    label
    {
        @include field-label;
    }

    .FieldIcon
    {
        @include field-icon;

        transition: transform $duration-quick ease-in-out;
    }

    .Input
    {
        @include button-behaviour;
        @include field-input;
        @include no-select;
        @include text-overflow;

        @include adjust
        {
            padding-right: 24px * $adj;
        }
    }

    &.Expand
    {
        z-index: 1;

        .FieldIcon
        {
            transform: rotate(180deg);
        }
    }

    &.Focus
    {
        & > .Input
        {
            @include field-input-focus;
        }
    }
}

.SelectFieldOption
{
    @include button-behaviour;
    @include no-select;
    @include slide-down;

    position: relative;

    @include adjust
    {
        padding: (8px * $adj) (16px * $adj);
    }

    &.Selected
    {
        color: $color-gold;
    }

    @include responsive-width($w-narrower)
    {
        @include slide-up;
    }
}

.SelectFieldOptions
{
    @include slide-down;

    position: relative;
    color: $color-white;
    background-color: $color-dark;
    overflow: auto;

    @include adjust
    {
        max-height: 300px * $adj;
        margin-right: -100px * $adj;
        padding: (8px * $adj) (100px * $adj) (8px * $adj) 0;
        font-size: 17px * $adj;
        line-height: 20px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        @include shadow-up;
        @include slide-up;

        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 24px 100px 24px 16px;
        border-radius: 0;
        z-index: 100000;
    }
}

.SelectFieldOptionsWrapper
{
    @include field-input-radius;

    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    overflow: hidden;

    @include adjust
    {
        margin-top: 16px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        position: fixed;
        top: 0;
        bottom: 0;
        margin-top: 0;
    }
}

.SelectFieldSelectedItem
{
    display: inline-block;
    position: relative;
    color: $color-white;
    background-color: $color-dark-light;

    @include adjust
    {
        margin: (4px * $adj) (4px * $adj);
        padding: 0 (8px * $adj);
        font-size: 14px * $adj;
        border-radius: 4px * $adj;
    }

    .IconButton
    {
        top: -.1em;
        
        @include adjust
        {
            left: 2px * $adj;
            width: 12px * $adj;
            height: 12px * $adj;
            margin-left: 4px * $adj;
        }
    }
}

.SelectFieldSelectedItems
{
    @include adjust
    {
        margin: (-4px * $adj) (-4px * $adj);
    }
}