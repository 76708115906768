
@use "sass:math";

@import "../../../../Import/SCSS/classes";

.ModuleInstagram
{
    text-align: center;

    h2
    {
        @include heading-4;

        text-transform: none;
    }

    @include adjust
    {
        padding: (96px * $adj) (48px * $adj) (160px * $adj);
    }

     @include responsive-width($w-narrow)
    {
        padding: 48px 72px 72px;
    }

    @include responsive-width($w-narrower)
    {
        padding: 48px 16px 64px;
    }
}

.ModuleInstagramItem
{
    display: inline-block;
    position: relative;
    width: math.div(100%, 6);
    background-color: $color-dark;

    .LoadImage
    {
        position: relative;
        padding: 0 0 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &:focus
    {
        z-index: 1;

        .LoadImage
        {
            outline: 2px solid $color-gold;
        }
    }

    @include responsive-width($w-narrow)
    {
        width: math.div(100%, 3);
    }
}

.ModuleInstagramItems
{
    position: relative;
    line-height: 0;

    @include adjust
    {
        margin: (32px * $adj) 0 0;
    }
}
