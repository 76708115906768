
@import "Import/SCSS/classes";

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

*
{
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );
}

html,
body
{
    position: relative;
    height: 100%;
    background-color: $color-dark;
}

body,
input,
select,
textarea
{
    @include font;

    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include adjust
    {
        font-size: $font-size * $adj;
    }
}

b,
strong
{
    font-weight: $font-weight-bold;
}

em,
i
{
    font-style: italic;
}

$short : 50%;

@include keyframes(fadeIn)
{
    0%
    {
        opacity: 0;
    }
    100%
    {
        opacity: 1;
    }
}

@include keyframes(slideDown)
{
    0%
    {
        transform: translateY(-100%);
    }
    100%
    {
        transform: translateY(-0%);
    }
}

@include keyframes(slideDownShort)
{
    0%
    {
        transform: translateY(#{$short * -1});
    }
    100%
    {
        transform: translateY(-0%);
    }
}

@include keyframes(slideLeft)
{
    0%
    {
        transform: translateX(100%);
    }
    100%
    {
        transform: translateX(-0%);
    }
}

@include keyframes(slideLeftShort)
{
    0%
    {
        transform: translateX(#{$short});
    }
    100%
    {
        transform: translateX(-0%);
    }
}

@include keyframes(slideRight)
{
    0%
    {
        transform: translateX(-100%);
    }
    100%
    {
        transform: translateX(-0%);
    }
}

@include keyframes(slideRightShort)
{
    0%
    {
        transform: translateX(#{$short * -1});
    }
    100%
    {
        transform: translateX(-0%);
    }
}

@include keyframes(slideUp)
{
    0%
    {
        transform: translateY(100%);
    }
    100%
    {
        transform: translateY(-0%);
    }
}

@include keyframes(slideUpShort)
{
    0%
    {
        transform: translateY(#{$short});
    }
    100%
    {
        transform: translateY(-0%);
    }
}