
@import "../../../../Import/SCSS/classes";

.TemplateEatery
{
    .CustomLogo
    {
        width: 14em;
    }
}

.TemplateEateryAbout
{
    @include content;
}

.TemplateEateryButtons .Button
{
    display: inline-block;
    width: 100%;

    @include responsive-width($w-narrow)
    {
        display: block;
        margin: 16px auto !important;
    }
}

.TemplateEateryButtons .Button + .Button
{
    @include adjust
    {
        margin-left: 16px * $adj;
    }
}

.eatery-buttons {
    margin-top: 32px;
    a + a {
        margin-top: 16px;
    }
}

.TemplateEateryEvent
{
    @include content-segment;

    margin: 0 auto;

    @include adjust
    {
        max-width: 900px * $adj;
    }

    h2
    {
        @include heading-3;

        text-transform: none;
    }

    .Button
    {
        @include adjust
        {
            margin: (16px * $adj) auto;
        }
    }
}

.TemplateEateryEventAbout
{
    @include content;
    @include adjust
    {
        margin-bottom: 36px * $adj;
    }
}

.TemplateEateryEventImage
{
    background-size: cover;
    background-repeat: no-repeat;

    @include adjust
    {
        margin-top: 72px * $adj;
    }
}

.TemplateEateryLeft
{
    text-align: left;

    h6
    {
        @include heading-6;

        text-transform: uppercase !important;

        @include adjust
        {
            line-height: 32px * $adj;
        }
    }

    .OpeningHoursItemHours
    {
        width: 50%;
    }

    .OpenStatus
    {
        position: absolute;
        top: 0;

        @include adjust
        {
            left: calc(50% - #{32px * $adj});
        }
    }
}

.TemplateEateryLeftContact
{
    //display: inline-block;
    position: relative;
    //width: 50%;
    margin-top: 0.5em;

    .Link
    {
        display: block;
    }
}

.TemplateEateryTop
{
    @include top-logo;
}