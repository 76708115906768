
@import "../../../../Import/SCSS/classes";

.ModuleNews
{
    @include adjust
    {
        padding: (160px * $adj) (48px * $adj);
    }

     @include responsive-width($w-narrow)
    {
        padding: 72px 48px;
    }

    @include responsive-width($w-narrower)
    {
        padding: 64px 16px;
    }
}

.ModuleNewsItem
{
    @include button-behaviour;
    @include no-select;

    display: inline-block;
    position: relative;
    width: 25%;
    vertical-align: top;
    text-align: center;

    h3
    {
        @include font-alt-1;

        position: absolute;
        top: 50%;
        right: 9%;
        left: 9%;
        font-size: 1.15vw;
        line-height: 1.27;
        transform: translateY(-50%);
    }

    h5
    {
        @include font-alt-1;
        @include heading-uppercase;

        position: absolute;
        top: 17.5%;
        right: 9%;
        left: 9%;
        font-size: 0.89vw;
    }

    &.AltFont h3
    {
        @include font-alt-2;

        font-size: 1.85vw;
        line-height: 1;
        transform: translateY(-50%) rotate(-10deg);
    }

    &.ColorTextWhite .ModuleNewsItemContent
    {
        color: $color-white;
    }

    &.HasBackground .ModuleNewsItemContent
    {
        background-color: $color-dark;

        h1, h2, h3, h4, h5, h6
        {
            @include shadow-overlay-text;
        }

        .Button
        {
            @include shadow-overlay;
        }
    }

    &.Height1
    {
        h3
        {
            @include adjust
            {
                //margin-top: -0.8em * $adj !important;
            }
        }

        .ModuleNewsItemContent
        {
            padding-top: 50%;

            @include responsive-width($w-narrower)
            {
                padding-top: 46.38%;
            }
        }
    }

    &.Height2 .ModuleNewsItemContent
    {
        padding-top: 100%;

        @include responsive-width($w-narrower)
        {
            padding-top: 92.75%;
        }
    }

    &:focus
    {
        outline: none;

        .ModuleNewsItemButton
        {
            @include button-focus;
        }

        .ModuleNewsItemContent
        {
            outline: 2px solid $color-gold;
        }
    }

    @include hover-active
    {
        .ModuleNewsItemButton
        {
            @include button-hover-active;
        }
    }

    @include responsive-width($w-narrow)
    {
        width: 50%;

        h3
        {
            font-size: 2.3vw;
        }

        h5
        {
            font-size: 1.78vw;
        }

        &.AltFont h3
        {
            font-size: 3.7vw;
        }
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;

        h3
        {
            font-size: 4.6vw;
        }

        h5
        {
            font-size: 3.56vw;
        }

        &.AltFont h3
        {
            font-size: 7.4vw;
        }
    }
}

.ModuleNewsItemBackgroundImage
{
    @include overlay;

    background-size: cover;
    background-repeat: no-repeat;
}

.ModuleNewsItemButton
{
    @include button-appearance;
    @include button-small;
    @include button-transition;

    position: absolute;

    @include adjust
    {
        right: 16px * $adj;
        bottom: 16px * $adj;
    }
}

.ModuleNewsItemContent
{
    position: relative;
    color: $color-dark;
    background-color: $color-beige;
}

.ModuleNewsItemContentLogo
{
    position: absolute;
    top: 25%;
    right: 0;
    bottom: 25%;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.ModuleNewsItemWrapper
{
    position: relative;

    @include adjust
    {
        padding: 12px * $adj;
    }
}

.ModuleNewsItems
{
    position: relative;
    
    @include adjust
    {
        margin: -12px * $adj;
    }
}