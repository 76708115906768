
@import "../../../Import/SCSS/classes";

.ItemPressContent
{
    position: relative;

    h4
    {
        @include heading-6;

        color: $color-gray;

        @include adjust
        {
            margin: (12px * $adj) 0;
        }
    }

    p
    {
        @include adjust
        {
            margin-bottom: 24px * $adj;
            font-size: 14px * $adj;

            @include max-rows(3, 20px * $adj);
        }
    }
}