@import "../../../Import/SCSS/classes";

.tabs {
    margin: -32px auto 72px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 99999px;
    background-color: rgba(0,0,0, .1);

    .Tab {
        border: none;
        background: transparent;
        padding: 16px 24px;

        border-radius: 99999px;

        cursor: pointer;
        font-family: "Roboto Slab", serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;

        -webkit-appearance: none;
        -moz-appearance: none;
        transition: all 0.3s ease;
    }

    .Tab:hover {
        text-decoration: underline;
    }

    .Tab.active {
        background-color: white;
    }
    .Tab.active:hover {
        text-decoration: none;
    }

}
