
@import "../../../Import/SCSS/classes";

.Confirm
{
    position: relative;
    text-align: center;

    @include adjust
    {
        padding: (72px * $adj) 0 (120px * $adj);
    }

    h2
    {
        @include heading-3;

        text-transform: none;
    }

    .Button .Spinner
    {
        @include adjust
        {
            right: 32px * $adj;
        }
    }
}

.ConfirmContent
{
    @include content;
}

.ConfirmButtons
{
    position: relative;

    @include adjust
    {
        margin-top: 40px * $adj;
    }

    .Button
    {
        .Icon
        {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        & + .Button
        {
            @include adjust
            {
                margin-top: 16px * $adj;
            }
        }
    }
}

.ConfirmIcon
{
    @include adjust
    {
        width: 96px * $adj;
        height: 96px * $adj;
        margin: (40px * $adj) 0;
    }
    
    &.ColorGreen
    {
        color: $color-green;
    }

    &.ColorRed
    {
        color: $color-red;
    }
}