
@import "../../../Import/SCSS/classes";

.CollapsableView
{
    position: relative;

    .CollapsableContent
    {
        @include adjust
        {
            padding: (16px * $adj) (32px * $adj) (32px * $adj);
        }
    }

    &.Expanded .CollapsableViewLabelIcon
    {
        transform: rotate(45deg);
    }
}

.CollapsableViewLabel
{
    @include button-behaviour;
    @include font-alt-1;

    position: relative;
    text-align: center;
    text-transform: uppercase;

    @include adjust
    {
        padding: (20px * $adj) 0;
        font-size: 14px * $adj;
        list-height: 16px * $adj;
    }

    &:focus
    {
        text-decoration: underline;
        outline: none;
    }
}

.CollapsableViewLabelIcon
{
    position: absolute;
    transition: transform .5s ease-in-out;

    @include adjust
    {
        top: 12px * $adj;
        right: 16px * $adj;
        width: 32px * $adj;
        height: 32px * $adj;
    }
}