.lang-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none !important;
    border: none;
    padding: 0;
    cursor: pointer;

    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);

    width: 40px;
    height: 40px;

    background: transparent;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    color: white;

    div {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    svg {
        width: 24px;
        height: 24px;
        fill: white;
    }

    @media (max-width: 1400px) {
        right: 80px;
        width: 30px;
        height: 30px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    @media (max-width: 1000px) {
        right: 100px;
        width: 40px;
        height: 40px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    @media (max-width: 700px) {
        right: 60px;
        width: 30px;
        height: 30px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.skiptranslate {
    display: none;
}
#goog-gt-tt {
    display: none !important;
}

#lang {
    position: absolute;
    top: 100%;
    right: 24px;
    margin-top: 24px;

    z-index: -10;
    background-color: #101010;
    opacity: 0;
    pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    padding: 40px;
    border: 2px solid white;
    border-radius: 8px;

    transition: opacity 0.3s ease;

    @media (max-width: 400px) {
        left: 24px;
    }

    &.active {
        z-index: 1000;
        opacity: 1;
        pointer-events: auto;

        .lang {
            opacity: 1;
        }
    }

    .skiptranslate {
        display: block;
    }

    .lang-toggle {
        width: 20px;
        height: 20px;
        top: 14px;
        right: 12px;
        transform: translateY(0);

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .lang {
        position: relative;
        opacity: 0;
        width: 260px;

        transition: opacity 0.3s ease;

        background-color: #101010;

        border-radius: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 400px) {
            width: 100%;
        }

        #google_translate_element {
            width: 100%;

            .goog-te-gadget {
                display: block;
                position: relative;
                text-align: center;

                div {
                    margin-bottom: 15px;
                }
            }

            select {
                outline: none !important;
                text-align-last: center;
                cursor: pointer;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: white;
                border: none;
                border-radius: 4px;
                display: block;
                width: 100%;
                padding: 16px 16px;
                color: #101010;
                font-size: 16px;
                line-height: 20px;
                font-family: "Roboto Slab", serif;
                font-weight: 600;

                &:hover {
                    background: #e3e3e3;
                }
                &:active {
                    background: white;
                }
            }
        }

    }
}
.clear-translate {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-top: 16px;

    color: white;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
.translated-ltr {
    .clear-translate {
        display: block;
    }
}
font {
    color: inherit !important;
    background-color: inherit !important;
    box-shadow: none !important;
}