
@import "../../../Import/SCSS/vars";

.LoadImage
{
    display: block;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &.Loaded
    {
        opacity: 1;
    }
}