
@import "../../../Import/SCSS/classes";

$f-height: 75vh;

.Full
{
    @include segment;
    
    position: relative;

    &.Top
    {
        z-index: 1;

        /*.FullTable
        {
            @include adjust
            {
                padding-top: $h-header * $adj;
                min-height: calc(#{$f-height} - #{$h-header * $adj});
            }

            @include responsive-width($w-narrow)
            {
                padding-top: $h-header-narrow;
                min-height: calc(#{$f-height} - #{$h-header-narrow});
            }
        }*/
    }

    @include responsive-width($w-narrow)
    {
        .FullTable
        {
            //padding-top: ($h-header-narrow + 40px) !important;
            padding-bottom: 40px;
            min-height: 80vw !important;
        }

        .SegmentButtons
        {
            //margin-top: 96px;
            margin-top: 120px;
        }
    }
}

.FullCell
{
    display: table-cell;
    position: relative;
    vertical-align: middle;
    text-align: center;
}

.FullContent
{
    position: relative;
    
    @include adjust
    {
        padding: 48px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        padding: (40px * $adj) (16px * $adj);
    }
}

.FullTable
{
    display: table;
    position: relative;
    width: 100%;
    min-height: $f-height;
}