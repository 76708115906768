
@import "../../../Import/SCSS/classes";

.ItemStaff
{
    @include no-select;

    display: inline-block;
    width: 25%;
    box-sizing: border-box;
    vertical-align: top;
    
    @include adjust
    {
        padding: 12px * $adj;
    }

    h3
    {
        @include font-alt-1;

        text-transform: none;
        
        @include adjust
        {
            font-size: 14px * $adj;
            line-height: 20px * $adj;
        }
    }

    h5
    {
        position: relative;
        color: $color-gray;
        text-transform: uppercase;

        @include adjust
        {
            margin: (8px * $adj) 0;
            font-size: 12px * $adj;
            line-height: 16px * $adj;
        }
    }

    .Link
    {
        display: block;
        white-space: nowrap;

        @include adjust
        {
            font-size: 14px * $adj;
            line-height: 20px * $adj;
        }

        &:focus
        {
            text-decoration: underline;
        }

        @include responsive-width($w-narrower)
        {
            margin: 4px 0;
        }
    }

    @include responsive-width($w-narrow)
    {
        width: 50%;
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;
    }
}

.ItemStaffContent
{
    position: relative;
    text-align: center;

    @include adjust
    {
        padding: 16px * $adj;
    }
}

.ItemStaffImage
{
    position: relative;
    margin: 0 auto;
    background-color: $color-dark;
    border-radius: 100%;
    overflow: hidden;

    @include adjust
    {
        max-width: 284px * $adj;
    }

    .LoadImage
    {
        display: block;
        padding: 0 0 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @include responsive-width($w-narrower)
    {
        max-width: 160px;
    }
}

.ItemStaffWrapper
{
    overflow: hidden;
}