
@import "../../../Import/SCSS/classes";

.Notification
{
    @include segment;
    @include shadow;
    @include slide-left;

    position: relative;
    text-align: center;
    overflow: hidden;

    @include adjust
    {
        width: 320px * $adj;
        margin: (24px * $adj) (48px * $adj);
        padding: (72px * $adj) (48px * $adj);
        border-radius: 16px * $adj;
    }
    
    p
    {
        color: $color-gray;

        @include adjust
        {
            margin: (32px * $adj) 0 (16px * $adj);
            font-size: 14px * $adj;
            line-height: 20px * $adj;
        }

        .Link
        {
            text-decoration: underline;
            text-transform: lowercase;
        }
    }

    .Button + .Button
    {
        @include adjust
        {
            margin-left: 8px * $adj;
        }
    }

    &.AltFont
    {
        h3
        {
            @include heading-3-alt;
        }
    }

    &.NormalFont
    {
        h3
        {
            @include heading-5;
        }
    }

    @include responsive-width($w-narrow)
    {
        padding: 24px 48px;
    }

    @include responsive-width($w-narrower)
    {
        @include slide-up;

        width: auto;
        margin: 8px 24px;
    }
}

.NotificationContent
{
    @include slide-left-short;

    @include responsive-width($w-narrower)
    {
        @include slide-up-short;
    }
}

.NotificationClose
{
    position: absolute;
    
    @include adjust
    {
        top: 16px * $adj;
        right: 16px * $adj;
        width: 24px * $adj;
        height: 24px * $adj;
    }
}

.Notifications
{
    position: fixed;
    right: 0;
    z-index: 300000;

    @include adjust
    {
        bottom: 24px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        left: 0;
        bottom: 8px;
    }
}