
@import "../../../../Import/SCSS/classes";

.TemplateConferenceEmpty
{
    @include empty;
}

.TemplateConferenceFields
{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include adjust
    {
        margin: 0 (-12px * $adj);
    }
}

.TemplateConferenceFieldsWrapper
{
    position: relative;
    margin: 0 auto;
    z-index: 1000;
    
    @include adjust
    {
        padding: (72px * $adj) (48px * $adj);
        max-width: 1191px * $adj;
    }

    .TemplateConferenceField
    {
        display: inline-block;
        width: 40%;
        box-sizing: border-box;
        vertical-align: top;

        @include adjust
        {
            padding: 0 (12px * $adj);
        }

        &:last-child
        {
            width: 20%;
        }
    }

    @include responsive-width($w-narrower)
    {
        padding: (24px * $adj) (16px * $adj);

        .TemplateConferenceField
        {
            display: block;
            width: auto !important;
            margin: 16px 0;
        }
    }
}

.TemplateConferenceItems,
.TemplateConferenceStaffItems
{
    @include items-container;
}

.TemplateConferenceItemsCount
{
    @include no-select;
    
    position: absolute;
    bottom: 100%;
    font-weight: 500;
    color: $color-gray;

    @include adjust
    {
        left: 16px * $adj;
        font-size: 14px * $adj;
        line-height: 20px * $adj;
    }
}

.TemplateConferenceItemsWrapper
{
    @include items-wrapper;
}

.TemplateConferenceStaffItemsWrapper
{
    @include items-wrapper-staff;
}

.TemplateConferenceTop
{
    position: relative;
    text-align: center;

    @include adjust
    {
        padding: (72px * $adj) (48px * $adj) 0;
    }

    h1
    {
        @include heading-3;
        text-transform: none;

        @include adjust
        {
            margin: (48px * $adj) 0 (24px * $adj);
        }
    }

    h3
    {
        @include heading-5;

        @include adjust
        {
            margin: 0 0 (48px * $adj);
        }
    }

    @include responsive-width($w-narrower)
    {
        padding: (48px * $adj) (16px * $adj) 0;
    }
}
