
@import "../../Import/SCSS/classes";

.Module
{
    &.HideDesktop
    {
        display: none;
    }

    @include responsive-width($w-narrow)
    {
        &.HideDesktop
        {
            display: block;
        }

        &.HideNarrow
        {
            display: none;
        }
    }
}