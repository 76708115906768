
@import "../../../Import/SCSS/classes";

.CardLogo
{
    svg
    {
        display: block;
        width: 100%;
        height: auto;
    }

    text
    {
        @include font-alt-2;
    }
}