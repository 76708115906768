@import "../../../Import/SCSS/classes";

.Announcement
{
    @include fade-in;

    position: relative;
    color: $color-white;
    background-color: $color-gold;

    @include adjust
    {
        padding: (12px * $adj) (48px * $adj);
    }

    a
    {
        text-decoration: underline;
    }

    @include responsive-width($w-narrower)
    {
        padding: 10px 15px;
    }
}

.AnnouncementButtons
{
    position: relative;
    float: right;
    white-space: nowrap;

    @include adjust
    {
        padding: (4px * $adj) 0;
    }

    .Button
    {
        @include adjust
        {
            margin-left: 8px * $adj;
        }
    }

    @include responsive-width($w-narrow)
    {
        display: none;
    }
}

.AnnouncementButtonsNarrow
{
    display: none;
    margin-top: 16px;
    text-align: right;

    .Button
    {
        margin-left: 8px;
    }

    @include responsive-width($w-narrow)
    {
        display: block;
    }
}

.AnnouncementMessage
{
    position: relative;

    @include adjust
    {
        padding: (8px * $adj) (48px * $adj) (8px * $adj) 0;
        font-size: 18px * $adj;
        line-height: 26px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        padding: 8px 0;
    }
}

.AnnouncementMessageWrapper
{
    position: relative;
    overflow: hidden;
}

.Announcements
{
    position: relative;
    z-index: 1000;
}