$color-beige:               #dcd5cd; @function color-beige($opacity: 1) {@return rgba(220, 213, 205, $opacity)};
$color-beige-dark:          #cbbeb1; @function color-beige-dark($opacity: 1) {@return rgba(203, 190, 177, $opacity)};
$color-black:               #000000; @function color-black($opacity: 1) {@return rgba(0, 0, 0, $opacity)};
$color-dark:                #335664; @function color-dark($opacity: 1) {@return rgba(51, 86, 100, $opacity)};
$color-dark-light:          #476773; @function color-dark-light($opacity: 1) {@return rgba(71, 103, 115, $opacity)};
$color-gold:                #b98952; @function color-gold($opacity: 1) {@return rgba(185, 137, 82, $opacity)};
$color-gold-dark:           #a77b49; @function color-gold-dark($opacity: 1) {@return rgba(167, 123, 73, $opacity)};
$color-gray:                #8d8d8d; @function color-gray($opacity: 1) {@return rgba(141, 141, 141, $opacity)};
$color-gray-dark:           #626262; @function color-gray-dark($opacity: 1) {@return rgba(98, 98, 98, $opacity)};
$color-gray-light:          #bababa; @function color-gray-light($opacity: 1) {@return rgba(186, 186, 186, $opacity)};
$color-green:               #008d02; @function color-green($opacity: 1) {@return rgba(0, 141, 2, $opacity)};
$color-red:                 #ff4442; @function color-red($opacity: 1) {@return rgba(255, 68, 66, $opacity)};
$color-white:               #ffffff; @function color-white($opacity: 1) {@return rgba(255, 255, 255, $opacity)};
$color-white-dark:          #e3e3e3; @function color-white-dark($opacity: 1) {@return rgba(227, 227, 227, $opacity)};
$color-xmas:                #b01b2e; @function color-xmas($opacity: 1) {@return rgba(176,27,46, $opacity)};

$font-family:               'Roboto', sans-serif;
$font-family-alt-1:         'Roboto Slab', serif;
$font-family-alt-2:         'NexaRustScriptR-2', serif;
$font-size:                 22px;
$font-weight:               400;
$font-weight-bold:          600;
$font-weight-alt-1:         600;
$font-weight-alt-2:         400;
$line-height:               1.27;

$duration:                  1s;
$duration-quick:            .8s;
$duration-quicker:          .2s;
$duration-stagger:          .025s;
$timing-in:                 ease-in;
$timing-out:                ease-out;
$timing-in-out:             ease;

$h-header:                  160px;
$h-header-narrow:           72px;
$w-mid:                     1500px;
$w-narrow:                  1000px;
$w-narrower:                700px;
$w-content:                 592px;

$break-points: (
    1400px: 0.75,
    1000px: 1
);