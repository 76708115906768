
@import "../../../../Import/SCSS/classes";

.TemplateStudio
{
    h1
    {
        @include heading-3;
        text-transform: capitalize;

        @include adjust
        {
            margin-top: 40px * $adj;
        }
    }

    h3
    {
        @include heading-5;
    }

    .Gallery
    {
        @include adjust
        {
            margin: (72px * $adj) 0;
        }
    }

    .TemplateStudioLocationCity
    {
        @include heading-6;

        display: block;
        color: $color-gray;
        font-weight: 500;

        @include adjust
        {
            margin: (16px * $adj) 0 (12px * $adj);
        }

        &:focus
        {
            color: $color-gold;
        }
    }

    .ThreeColumnsCenter
    {
        .ThreeColumnsContent
        {
            max-width: none;
        }
    }
}

.TemplateStudioLocation
{
    @include adjust
    {
        padding: (96px * $adj) (36px * $adj);
    }

    h2
    {
        @include heading-3;

        text-align: center;
        text-transform: none;
    }
}

.TemplateStudioAbout
{
    @include content;

    text-align: left;

    @include adjust
    {
        margin: (48px * $adj) 0;
    }
}

.TemplateStudioAboutLocation
{
    @include content;

    text-align: left;

    @include adjust
    {
        margin: (16px * $adj) 0 (32px * $adj)
    }
}

.TemplateStudioAddress
{
    position: relative;

    @include adjust
    {
        margin-top: 48px * $adj;
        font-size: 14px * $adj;
        line-height: 20px * $adj;
    }
}

.TemplateStudioContent
{
    position: relative;
    margin: 0 auto;

    @include adjust
    {
        max-width: 593px * $adj;
    }
}

.TemplateStudioFeature
{
    display: inline-block;
    position: relative;
    width: 50%;
    box-sizing: border-box;
    text-align: center;

    @include adjust
    {
        padding: 16px * $adj;
    }

    .Icon
    {
        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;
        padding: 24px;
    }
}

.TemplateStudioFeatureLabel
{
    font-weight: 500;
    text-transform: uppercase;

    @include adjust
    {
        margin-top: 4px * $adj;
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.TemplateStudioFeatures
{
    @include no-select;
    @include segment;

    text-align: left;

    @include adjust
    {
        padding: 48px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        padding: 24px;
    }
}

.TemplateStudioHeaderDesktop
{
    @include responsive-width($w-narrow)
    {
        display: none;
    }
}

.TemplateStudioHeaderNarrow
{
    display: none;
    text-align: center;

    @include responsive-width($w-narrow)
    {
        display: block;
        padding: 72px 16px 40px;
    }
}

.TemplateStudioLeft
{
    text-align: left;
}

.TemplateStudioMap
{
    position: relative;
    padding: 0 0 100%;
}

.TemplateStudioStaffItemsWrapper
{
    position: relative;
    overflow: hidden;
    text-align: center;

    @include adjust
    {
        padding: (120px * $adj) (48px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: (72px * $adj) (16px * $adj);
    }
}