
@import "../../../Import/SCSS/classes";

.ProductItem
{
    position: relative;
    background-color: $color-white;
    overflow: hidden;

    @include adjust
    {
        height: 240px * $adj;
        border-radius: 20px * $adj;
    }
}

.ProductItemButtons
{
    position: absolute;
    line-height: 0;
    text-align: right;
    
    @include adjust
    {
        right: 16px * $adj;
        bottom: 16px * $adj;
    }

    .Button
    {
        margin-top: 8px * $adj;
    }
}

.ProductItemContent
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    text-align: left;
    box-sizing: border-box;

    @include adjust
    {
        padding: 16px * $adj;
    }
}

.ProductItemImage
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: $color-dark;

    .LoadImage
    {
        @include overlay;

        background-repeat: no-repeat;
        background-size: cover;
    }
}

.ProductItemWrapper
{
    @include no-select;
    position: relative;
    box-sizing: border-box;
}