
@import "../../../../Import/SCSS/classes";

.TemplateMeetingRoom
{
    h1
    {
        @include heading-3;
        text-transform: capitalize;
    }

    h3
    {
        @include heading-5;
        text-align: center;

        @include adjust
        {
            margin-bottom: 40px * $adj;
        }
    }

    .TemplateMeetingRoomLocationLink
    {
        @include heading-6;

        display: block;
        color: $color-gray;
        font-weight: 500;
        text-decoration: underline;

        @include adjust
        {
            margin: (16px * $adj) 0 (12px * $adj);
        }

        &:focus
        {
            color: $color-gold;
        }
    }

    .ThreeColumnsCenter
    {
        .Button + .Button
        {
            @include adjust
            {
                margin-top: 16px * $adj;
            }
        }
        .ThreeColumnsContent
        {
            max-width: none;
        }
    }
}

.TemplateMeetingRoomGallery
{
    @include adjust
    {
        margin: (72px * $adj) 0;
    }
}

.TemplateMeetingRoomLocation
{
    @include adjust
    {
        padding: (96px * $adj) (36px * $adj);
    }

    h2
    {
        @include heading-3;

        text-align: center;
        text-transform: none;
    }
}

.TemplateMeetingRoomAbout
{
    @include content;

    text-align: left;

    @include adjust
    {
        margin: (48px * $adj) 0;
    }
}

.TemplateMeetingRoomAboutLocation
{
    @include content;

    text-align: left;

    @include adjust
    {
        margin: (16px * $adj) 0 (32px * $adj)
    }
}

.TemplateMeetingRoomAddress
{
    position: relative;

    @include adjust
    {
        margin-top: 48px * $adj;
        font-size: 14px * $adj;
        line-height: 20px * $adj;
    }
}

.TemplateMeetingRoomContent
{
    position: relative;

    @include adjust
    {
        margin: 0 auto (72px * $adj);
        max-width: 593px * $adj;
    }
}

.TemplateMeetingRoomFeature
{
    display: inline-block;
    position: relative;
    width: 50%;
    box-sizing: border-box;
    text-align: center;

    @include adjust
    {
        padding: 16px * $adj;
    }

    .Icon
    {
        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;
        padding: 24px;
    }
}

.TemplateMeetingRoomFeatureLabel
{
    font-weight: 500;
    text-transform: uppercase;

    @include adjust
    {
        margin-top: 4px * $adj;
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.TemplateMeetingRoomFeatures
{
    @include no-select;
    @include segment;

    text-align: left;

    @include adjust
    {
        padding: 48px * $adj;
    }

    @include responsive-width($w-narrower)
    {
        padding: 24px;
    }
}

.TemplateMeetingRoomHeaderDesktop
{
    @include responsive-width($w-narrow)
    {
        display: none;
    }
}

.TemplateMeetingRoomHeaderNarrow
{
    display: none;
    text-align: center;

    @include responsive-width($w-narrow)
    {
        display: block;
        padding: 72px 16px 40px;
    }
}

.TemplateMeetingRoomLeft
{
    text-align: left;
}

.TemplateMeetingRoomMap
{
    position: relative;
    padding: 0 0 100%;
}

.TemplateMeetingRoomRelatedItems
{
    @include items-container;
}

.TemplateMeetingRoomRelatedItemsGallery
{
    display: none;
    @include items-container;

    .GalleryItems
    {
        padding-top: 0 !important;

        @include adjust
        {
            height: 410px * $adj;
        }
    }

    .ItemRoom
    {
        display: block !important;
        width: auto !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .ItemRoomImage
    {
        padding-bottom: 0 !important;

        @include adjust
        {
            height: 200px * $adj;
        }
    }
}

.TemplateMeetingRoomRelatedItemsWrapper
{
    @include items-wrapper;

    text-align: center;

    h2
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin: 0 0 (72px * $adj);
        }
    }

    @include responsive-width($w-narrower)
    {
        .TemplateMeetingRoomRelatedItems
        {
            display: none;
        }

        .TemplateMeetingRoomRelatedItemsGallery
        {
            display: block;
        }
    }
}

.TemplateMeetingRoomStaffItemsWrapper
{
    position: relative;
    overflow: hidden;
    text-align: center;

    @include adjust
    {
        padding: (120px * $adj) (48px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: (72px * $adj) (16px * $adj);
    }
}