
@import "../../../Import/SCSS/classes";

.Spinner
{
    @include button-behaviour;
    
    display: inline-block;
    position: relative;
    vertical-align: middle;
    perspective: 500px;
    transform-style: preserve-3d;

    .Icon
    {
        display: block;
        animation: spinnerRotate 2s linear infinite both;

        @include adjust
        {
            width: 50px * $adj;
            height: 50px * $adj;
        }
    }
}

@include keyframes(spinnerRotate)
{
    0%
    {
        transform: rotateY(0deg);
    }

    100%
    {
        transform: rotateY(360deg);
    }
} 