
@import "../../../../Import/SCSS/classes";

.TemplateContactEateries
{
    @include content-segment;

    h2
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin-bottom: 96px * $adj;
        }
    }
}

.TemplateContactEateryItem
{
    @include segment;

    text-align: center;
    box-sizing: border-box;

    @include adjust
    {
        height: 320px * $adj;
        padding: (48px * $adj) (16px * $adj);
    }

    .LocalLogo
    {
        @include adjust
        {
            height: 72px * $adj;
            margin-bottom: 16px * $adj;
        }
    }
}

.TemplateContactEateryItems
{
    position: relative;
    text-align: left;

    @include adjust
    {
        margin: -16px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        margin: (-16px * $adj) 0;
    }

    @include responsive-width($w-narrower)
    {
        margin: -12px;
    }
}

.TemplateContactEateryItemRows
{
    position: relative;

    @include adjust
    {
        margin: (16px * $adj) 0;
        font-size: 14px * $adj;
        line-height: 20px * $adj;
    }

    .Link
    {
        display: block;
    }
}

.TemplateContactEateryItemWrapper
{
    display: inline-block;
    width: 25%;
    vertical-align: middle;
    box-sizing: border-box;

    @include adjust
    {
        padding: 12px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        width: 50%;
    }

    @include responsive-width($w-narrower)
    {
        display: block;
        width: auto;
    }
}

.TemplateContactStaff
{
    @include content-segment;

    h2
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin-bottom: 96px * $adj;
        }
    }
}

.TemplateContactStaffItems
{
    @include items-container;
}

.TemplateContactTop
{
    text-align: center;

    h5
    {
        @include heading-5;

        @include adjust
        {
            margin-bottom: 40px * $adj;
        }
    }
}

.TemplateContactText
{
    @include content;
}