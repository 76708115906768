
@import "../../../../Import/SCSS/classes";

.TemplatePropertyOwnersAbout
{
    @include content;

    @include adjust
    {
        margin: (40px * $adj) 0;
    }
}

.TemplatePropertyOwnersConsultant
{
    @include segment;

    margin: 0 auto 0 0;
    box-sizing: border-box;

    @include adjust
    {
        max-width: 437px * $adj;
        padding: 40px * $adj;
        border-radius: 16px * $adj;
    }

    .ItemStaff
    {
        display: block;
        width: auto;
    }

    @include responsive-width($w-narrow)
    {
        margin: 0 auto;
    }

    @include responsive-width($w-narrower)
    {
        max-width: none;
    }
}

.TemplatePropertyOwnersConsultantAbout
{
    @include content;

    @include adjust
    {
        margin-bottom: 40px * $adj;
        
        p
        {
            font-size: 14px * $adj;
            line-height: 20px * $adj;
        }
    }
}

.TemplatePropertyOwnersListWrapper
{
    @include segment;

    position: relative;

    @include adjust
    {
        margin: (96px * $adj) 0;
        padding: (40px * $adj) 0;
    }

    h3
    {
        font-weight: 500;
        text-transform: uppercase;

        @include adjust
        {
            font-size: 14px * $adj;
            line-height: 16px * $adj;
        }
    }
}

.TemplatePropertyOwnersListItem
{
    display: inline-block;
    position: relative;
    width: 50%;
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: top;
    box-sizing: border-box;

    @include adjust
    {
        margin: (20px * $adj) 0;
        padding: 0 (40px * $adj);
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }

    .Icon
    {
        display: block;

        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
            margin: 0 auto (4px * $adj);
        }
    }
}

.TemplatePropertyOwnersListItems
{
    position: relative;

    @include adjust
    {
        margin: (20px * $adj) 0 0;
    }
}

.TemplatePropertyOwnersTitle
{
    @include heading-5;
}