
@import "../../../Import/SCSS/classes";

.LocalLogo
{
    display: inline-block;
    position: relative;
    text-align: center;

    h1, h2, h3, h4, h5, h6
    {
        @include font-alt-2;

        position: relative;
        margin: 0 !important;
        font-size: 2em;
        line-height: 1;
    }

    .MainLogo
    {
        width: auto;
        height: 0.9em;

        svg
        {
            width: auto;
            height: 100%;
            line-height: 1;
        }
    }

    .LumaFabriken,
    .VanDerNootska
    {
        width: 50%;
        margin: 0 auto;

        svg
        {
            width: 100%;
            height: 100%;
        }

        .CustomLogoText path
        {
            fill: currentColor;
        }

        @include responsive-width($w-narrow)
        {
            width: 35%;
        }
    }

    .LumaFabriken
    {
        .st0{fill:#FFFFFF;}
        .st1{fill:url(#SVGID_1_);}
        .st2{fill:url(#SVGID_00000040563865066911356980000005875278944342970263_);}
        .st3{fill:url(#SVGID_00000096771955857370628070000013935829542395950993_);}
        .st4{fill:url(#SVGID_00000017482469667553163660000000853501356613876148_);}
        .st5{fill:url(#SVGID_00000134251745562877841340000011961034929754598826_);}
        .st6{fill:url(#SVGID_00000008124764266080693700000014143106400206563217_);}
        .st7{fill:url(#SVGID_00000128453434729626389210000007911780558848271240_);}
        .st8{fill:url(#SVGID_00000071517518894339604920000008604672667607267261_);}
        .st9{fill:url(#SVGID_00000132794817878090712860000013233194311558335660_);}
        .st10{fill:url(#SVGID_00000047057580420405924360000007242338065816004761_);}
        .st11{fill:url(#SVGID_00000049197079835252951230000006193640858259518607_);}
        .st12{fill:url(#SVGID_00000122688876588117958710000000434033917150653073_);}
        .st13{fill:url(#SVGID_00000120559922055994762000000002273648327979077768_);}
        .st14{fill:url(#SVGID_00000104677576764252866770000016866762333634271111_);}
        .st15{fill:url(#SVGID_00000150800534104192056200000001605053067710409140_);}
        .st16{fill:url(#SVGID_00000021802148348828485350000009714822023101387151_);}
        .st17{fill:url(#SVGID_00000090982996177541772750000002721814681783568796_);}
        .st18{fill:url(#SVGID_00000179627906326181911230000012731731917763612592_);}
        .st19{fill:url(#SVGID_00000170275876430453470510000012563949105364908695_);}
        .st20{fill:url(#SVGID_00000163773482123177598370000001573513418054000319_);}
        .st21{fill:url(#SVGID_00000062896333317302547250000011752943233593463955_);}
        .st22{fill:url(#SVGID_00000056408442101644419870000007235492833078768294_);}
        .st23{fill:url(#SVGID_00000163775899438406865630000006782128069002794398_);}
        .st24{fill:url(#SVGID_00000034787995625526757990000010915417280331558303_);}
        .st25{fill:url(#SVGID_00000060717903026194755010000011427486527546045570_);}
        .st26{fill:url(#SVGID_00000082340051565151994930000002146649792007624357_);}
        .st27{fill:url(#SVGID_00000052067602232469151320000001737538012667747477_);}
        .st28{fill:url(#SVGID_00000160875558746364144650000010856592486573486007_);}
        .st29{fill:url(#SVGID_00000139973549161732977600000017179038231931767188_);}
        .st30{fill:url(#SVGID_00000085933058913548951520000015963699431918106032_);}
        .st31{fill:#4D4D4D;}
        .st32{fill:url(#SVGID_00000007404344351671846550000000233825314379195301_);}
        .st33{fill:url(#SVGID_00000083775439654473078210000016990666541976856510_);}
    }

    .VanDerNootska
    {
        path
        {
            fill: $color-dark;

            &.st0
            {
                fill: $color-white;
            }
        }
    }

    &.Rotate
    {
        h1, h2, h3, h4, h5, h6
        {
            transform: rotate(-10deg);
        }
    }
}