
@import "../../../Import/SCSS/classes";

.FixedButton
{
    @include button-behaviour;
    @include button-colors;
    @include button-transition;
    @include font-alt-1;

    position: fixed;
    right: 0;
    bottom: 0;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;
    z-index: 999999;

    @include adjust
    {
        padding: (16px * $adj) (32px * $adj);
        min-width: 332px * $adj;
        font-size: 14px * $adj;
        list-height: 16px * $adj;
    }

    &:focus
    {
        @include button-focus;
    }

    @include hover-active
    {
        @include button-hover-active;
    }

    @include responsive-width($w-narrower)
    {
        min-width: 0;
        font-size: 13px;
    }
}