
@import "../../../../Import/SCSS/classes";

.TemplatePressRelease
{
    .Loading
    {
        position: relative !important;
        color: currentColor !important;
        background-color: transparent !important;
        
        @include adjust
        {
            height: 200px * $adj;
        }
    }
}

.TemplatePressReleaseBody,
.TemplatePressReleaseIntro
{
    @include content;

    overflow: hidden;
}

.TemplatePressReleaseContent
{
    @include content-segment;

    padding-top: 0 !important;

    p
    {
        text-align: left;
    }
    .Gallery
    {
        @include adjust
        {
            max-width: 900px * $adj;
            margin: (96px * $adj) auto 0;
        }
    }
}

.TemplatePressReleaseError
{
    h2
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin: 0 0 (72px * $adj);
        }
    }
}

.TemplatePressRelated
{
    @include content-segment;

    & > h3
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin: 0 0 (72px * $adj);
        }
    }

    & > .Button
    {
        @include adjust
        {
            margin: (72px * $adj) auto 0;
        }
    }
}

.TemplatePressRelatedItem
{
    vertical-align: top;
}

.TemplatePressRelatedItems
{
    @include items-container;
}

.TemplatePressReleaseTop
{
    @include content-segment;

    padding-bottom: 0 !important;

    h1
    {
        @include heading-3;

        margin-right: auto;
        margin-left: auto;
        text-transform: none;

        @include adjust
        {
            max-width: $w-content * $adj;
        }
    }

    h3
    {
        @include heading-5;

        @include adjust
        {
            margin-bottom: 40px * $adj;
        }
    }

    h4
    {
        @include heading-6;

        color: $color-gray;
    }
}