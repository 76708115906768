
@import "../../../Import/SCSS/vars";

.LoadVideo
{
    opacity: 0;
    transition: opacity 1s ease-in-out;

    video
    {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.Playing
    {
        opacity: 1;
    }
}