@import "../../../../Import/SCSS/classes";

.TemplateNewsItemsWrapper {
    @include items-wrapper;
}

.TemplateNewsItems {
    @include items-container;

    img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
    }
}

.TemplateNewsTop {
    @include content-segment;

    padding-bottom: 0 !important;

    h1 {
        @include heading-3;

        text-transform: none;
    }
}
