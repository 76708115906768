
@import "../../../Import/SCSS/classes";

.LeftSide
{
    @include overlay(0, fixed);

    display: none;
    z-index: 2000000;

    @include responsive-width($w-narrow)
    {
        display: block;
    }
}

.LeftSideClose
{
    @include fade-in;

    display: block;
    position: absolute;
    animation-delay: $duration-quick;

    @include adjust
    {
        top: 24px * $adj;
        right: 16px * $adj;
        width: 32px * $adj;
        height: 32px * $adj;
    }
}

.LeftSideNavigationContainer
{
    @include slide-right;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    color: $color-white;
    background-color: $color-dark;
    overflow: auto;

    @include adjust
    {
        width: 326px * $adj;
        padding: 150px * $adj 0;
    }

    @include responsive-width($w-narrower)
    {
        width: 280px;
    }

    .NavigationItems > .NavigationItem > .NavigationItemLink .NavigationItemLinkLabel
    {
        @include slide-right-short;
    }

    @include stagger(".NavigationItem")
    {
        .NavigationItemLinkLabel
        {
            animation-delay: $sm * $duration-stagger !important;
        }
    }
}

.LeftSideOverlay
{
    @include fade-in;
    @include overlay;

    backdrop-filter: blur(10px) brightness(60%);
}