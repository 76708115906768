
@import "../../../../Import/SCSS/classes";

.TemplateCatering
{
    .Button
    {
        @include adjust
        {
            margin: (40px * $adj) auto (72px * $adj);
        }
    }

    .ThreeColumnsCenter
    {
        .ThreeColumnsContent
        {
            max-width: none;
        }
    }
}

.TemplateCateringAbout
{
    @include content;
}

.TemplateCateringStaffItems
{
    @include items-container;
}

.TemplateCateringStaffItemsWrapper
{
    @include items-wrapper;
}

.TemplateCateringTop
{
    h1
    {
        @include heading-1;
    }
}

.TemplateCateringAbout + div {
    .FullTable {
        min-height: 0 !important;
    }
    .SegmentButtons {
        margin-top: 0 !important;
        margin-bottom: 30px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .Button {
            margin: 0 auto !important;
            width: 100%;
        }
    }
}