
@import "../../../Import/SCSS/classes";

.ItemLarge
{
    position: relative;
    overflow: hidden;
    background-color: $color-white;
    vertical-align: top;

    @include adjust
    {
        height: 400px * $adj;
        border-radius: 16px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        height: auto;
    }
}

.ItemLargeButtons
{
    position: absolute;

    @include adjust
    {
        right: 16px * $adj;
        bottom: 16px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: 32px;
        text-align: right;
    }
}

.ItemLargeContent
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    text-align: center;
    box-sizing: border-box;

    @include adjust
    {
        padding: 40px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        position: relative;
        width: auto;
        padding: 32px 16px;
    }
}

.ItemLargeImage
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: $color-dark;

    .LoadImage
    {
        @include overlay;

        background-size: cover;
        background-repeat: no-repeat;
    }

    @include responsive-width($w-narrow)
    {
        position: relative;
        width: auto;
        padding: 0 0 50%;
    }
}