
@import "../../../Import/SCSS/classes";

.VideoPlayer
{
    @include overlay;

    iframe
    {
        @include overlay;

        width: 100%;
        height: 100%;
    }
}

.VideoPlayerContainer
{
    position: relative;

    &.Disabled
    {
        .VideoPlayerPoster
        {
            color: color-white-rgba( 0.8 ) !important;
        }

        .VideoPlayerPosterIcon
        {
            opacity: .5;
        }
    }

    &.Show
    {
        .VideoPlayerPoster
        {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.VideoPlayerContent
{
    position: relative;
    padding-top: 62.5%;
    background-color: currentColor;
    overflow: hidden;
}

.VideoPlayerPoster
{
    @include overlay;

    color: color-white-rgba(0.8);
    background-color: $color-black;
    cursor: pointer;

    @include hover
    {
        color: color-white-rgba(1);
    }

    &:focus
    {
        &:after
        {
            @include overlay;

            display: block;
            pointer-events: none;
            border: 2px solid $color-gold;
            content: "";
        }

        .VideoPlayerPosterIcon
        {
            background-color: $color-gold !important;
        }
    }
}

.VideoPlayerPosterIcon
{
    @include no-select;

    position: absolute !important;
    top: 50%;
    left: 50%;
    width: 5%;
    padding: 2%;
    color: $color-white;
    background-color: color-black(0.5);
    transform: translate(-50%, -50%);

    svg
    {
        width: 100%;
        height: auto;
    }
}

.VideoPlayerPosterImage
{
    @include overlay;
    background-size: cover;
    background-position: center center;
}

.VideoPlayerPosterLabel
{
    position: absolute;
    right: 10%;
    bottom: 10%;
    left: 10%;
    font-style: italic;
    text-transform: none;
    pointer-events: none;
    animation: fade-up $duration * 0.5 ease;
}