
@import "../../../../Import/SCSS/classes";

.RadiosField
{
    @include field;

    label
    {
        @include field-label;
    }
}

.RadiosFieldItem
{
    @include button-behaviour;

    position: relative;

    @include adjust
    {
        margin: (16px * $adj) 0;
    }

    .Input
    {
        position: relative;
        float: left;
        border: 1px solid currentColor;
        box-sizing: border-box;

        @include adjust
        {
            width: 16px * $adj;
            height: 16px * $adj;
            margin-right: 8px * $adj;
            border-radius: 16px * $adj;
        }
    }

    &.Selected
    {
        .Input:after
        {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: currentColor;
            transform: translate(-50%, -50%);
            content: "";

            @include adjust
            {
                width: 6px * $adj;
                height: 6px * $adj;
                border-radius: 6px * $adj;
            }
        }
    }

    &:focus
    {
        color: $color-gold;
        outline: none;
    }
}

.RadiosFieldItemLabel
{
    position: relative;
    overflow: hidden;
    font-weight: 500;
    text-transform: uppercase;

    @include adjust
    {
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }
}

.RadiosFieldItems
{
    position: relative;
}