
@import "../../../Import/SCSS/classes";

.Gallery
{
    position: relative;
    text-align: center;
}

.GalleryItem
{
    @include overlay;
    @include no-select;

    & > .LoadImage,
    & > .LoadVideo
    {
        @include overlay;
    }

    & > .LoadImage
    {
        background-repeat: no-repeat;
        background-size: cover;
    }

    & > .LoadVideo
    {
        @include overlay;

        background-color: $color-dark;
    }
}

.GalleryItems
{
    position: relative;
    padding-top: 53.33%;
    overflow: hidden;
}

.GalleryNavigation
{
    @include adjust
    {
        padding: (16px * $adj) 0;
    }
}