
@import "../../../Import/SCSS/classes";

.Loading
{
    @include no-select;
    @include overlay(0, fixed);

    color: $color-white;
    background-color: $color-gray;
}

.LoadingContent
{
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    white-space: nowrap;
}

.LoadingLabel
{
    @include font-alt-1;

    position: relative;

    @include adjust
    {
        margin-top: 50px * $adj;
        font-size: 17px * $adj;
        line-height: 28px * $adj;
    }
}

.LoadingLabelDots
{
    display: inline-block;
    position: relative;
}

.LoadingLabelDots1
{
    visibility: hidden;
}

.LoadingLabelDots2
{
    position: absolute;
    top: 0;
    left: 0;
}