
@import "../../../../Import/SCSS/classes";

.TemplateAcademyAbout
{
    @include content;

    text-align: left;

    @include adjust
    {
        margin-top: 72px * $adj;
    }
}

.TemplateAcademyBadge
{
    position: relative;
    margin: 0 auto;

    @include adjust
    {
        max-width: 192px * $adj;
    }

    .LoadImage
    {
        background-size: cover;
        background-repeat: no-repeat;
    }

    @include responsive-width($w-narrower)
    {
        margin: 0;
    }
}

.TemplateAcademyContact
{
    text-align: left;

    @include adjust
    {
        margin-top: 40px * $adj;
    }

    h2
    {
        @include heading-6;
    }
}

.TemplateAcademyContactItem
{
    @include adjust
    {
        margin: (24px * $adj) 0;
    }

    h3
    {
        @include heading-5;
        
        text-transform: none;
    }

    h6
    {
        @include heading-6;

        color: $color-gray;

        @include adjust
        {
            margin: (8px * $adj) 0;
        }
    }

    .Link
    {
        display: block;

        @include adjust
        {
            font-size: 14px * $adj;
            line-height: 20px * $adj;
        }
    }
}

.TemplateAcademyTop
{
    h1
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin: 40px * $adj;
        }
    }

    h5
    {
        @include heading-5;
    }
}