
@import "../../../Import/SCSS/classes";

.MainLogo
{
    display: inline-block;
    position: relative;
    vertical-align: middle;

    @include adjust
    {
        width: 196px * $adj;
    }

    svg
    {
        display: block;
        width: 100%;
        height: auto;
        fill: currentColor;
    }
}