
@import "../../../../Import/SCSS/classes";

.TemplateLocation
{
    .ThreeColumnsCenter
    {
        .ThreeColumnsContent
        {
            max-width: none;
        }
    }
}

.TemplateLocationAbout
{
    @include content;

    text-align: center;
}

.TemplateLocationAboutMore
{
    @include content;
    @include content-segment;

    padding-top: 0 !important;
    text-align: center;
}

.TemplateLocationAddress
{
    position: relative;

    @include adjust
    {
        padding: (16px * $adj) 0 0;
        font-size: 14px * $adj;
        line-height: 20px * $adj;
    }
}

.TemplateLocationContent
{
    position: relative;

    h3
    {
        @include heading-6;

        color: $color-gray;

        @include adjust
        {
            margin-bottom: 8px * $adj;
        }
    }

    .Button
    {
        @include adjust
        {
            margin: (40px * $adj) auto (16px * $adj);

            &:last-child
            {
                margin-bottom: 72px * $adj;
            }
        }
    }

    .Button + .Button
    {
        @include adjust
        {
            margin-top: 16px * $adj;
        }
    }
}

.TemplateLocationGallery
{
    position: relative;

    @include adjust
    {
        margin: 0 (16px * $adj) (72px * $adj);
    }
}

.TemplateLocationItems
{
    @include items-container;
}

.TemplateLocationItemsWrapper
{
    @include content-segment;

    h2
    {
        @include heading-3;

        text-transform: none;

        @include adjust
        {
            margin-bottom: 72px * $adj;
        }
    }
}

.TemplateLocationLinksBottom
{
    display: none;

    @include adjust
    {
        margin-bottom: 72px * $adj;
    }

    .LinksItemLabel
    {
        font-size: .8em;
        white-space: normal;
    }

    @include responsive-width($w-narrow)
    {
        display: block
    }
}

.TemplateLocationLinksLeft
{
    .LinksItemLabel
    {
        font-size: .8em;
        white-space: normal;
    }

    @include responsive-width($w-narrow)
    {
        display: none;
    }
}

.TemplateLocationLogo
{
    @include top-logo;
    
    display: inline-block;
    position: relative;

    @include adjust
    {
        margin-bottom: 72px * $adj;
    }

    .CustomLogo .EateryLogo
    {
        width: 150%;
        transform: translateX(-12.5%);

        @include responsive-width($w-narrower)
        {
            width: 100%;
            transform: none;
        }
    }
}

.TemplateLocationLogoBadge
{
    $s: 3.8em;

    position: absolute;
    top: 80%;
    left: 100%;
    width: $s;
    height: $s;

    .LoadImage
    {
        @include overlay;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .CustomLogo + &
    {
        left: 125%;

        @include responsive-width($w-narrower)
        {
            left: 100%;
        }
    }

    @include responsive-width($w-narrower)
    {
        left: auto;
        top: 120%;
        right: 0;
    }
}

.TemplateLocationMap
{
    position: relative;
    padding: 0 0 50%;
    overflow: hidden;

    @include adjust
    {
        margin: 0 (16px * $adj);
    }
}

.TemplateLocationStaffItems
{
    @include items-container;
}

.TemplateLocationStaffItemsWrapper
{
    @include items-wrapper;
}

.TemplateLocationTop
{
    overflow: hidden;
}