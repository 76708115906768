
@import "../../../../Import/SCSS/classes";

.ModuleTop
{
    h1
    {
        @include heading-1;
        @include heading-uppercase;
        @include no-select;
        @include shadow-overlay-text;
    }

    h2
    {
        @include heading-2-alt;
        @include no-select;
        @include shadow-overlay-text;

        @include adjust
        {
            margin: (32px * $adj) 0;
        }

        @include responsive-width($w-narrow)
        {
            margin: 16px 0;
        }
    }

    p
    {
        @include adjust
        {
            max-width: 700px * $adj;
            margin: (32px * $adj) auto;
        }
    }

    .Button
    {
        @include shadow-overlay;
    }
}