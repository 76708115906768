
@import "../../../Import/SCSS/classes";

.Header
{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100000;

    .MainNavigation
    {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (min-width: 1001px) and (max-width: 1400px) {
            margin-left: 40px;
        }

        @media screen and (min-width: 1401px) {
            margin-left: 58px;
        }
    }

    &.Instant
    {
        transition: transform $duration-quicker $timing-in-out;

        &.Hide
        {
            transform: translateY(-100%);
        }
    }
}

.HeaderCardButton,
.HeaderSpinner
{
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);

    @include adjust
    {
        right: 48px * $adj;
        width: 32px * $adj;
        height: 32px * $adj;
    }
}

.HeaderMenuButton
{
    display: none !important;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);

    @include adjust
    {
        left: 50px * $adj;
        width: 32px * $adj;
        height: 32px * $adj;
    }
}

.HeaderWrapper
{
    position: relative;
    color: $color-white;
    background-color: $color-dark;
    box-sizing: border-box;

    @include adjust
    {
        height: $h-header * $adj;
        padding: (53px * $adj) (48px * $adj) (50px * $adj);
    }
}

@include responsive-width($w-mid)
{
    .Header .MainNavigation
    {
        //left: 57%;
    }
}

@include responsive-width($w-narrow)
{
    .HeaderWrapper
    {
        height: $h-header-narrow;
        padding: 24px 0 16px;

        .MainLogo
        {
            display: block;
            width: 112px;
            margin: 0 auto;
        }

        .MainNavigation
        {
            display: none !important;
        }
    }

    .HeaderCardButton,
    .HeaderSpinner
    {
        right: 50px;

        .IconButtonLabel
        {
            display: none;
        }
    }

    .HeaderMenuButton
    {
        display: block !important;
    }
}

@include responsive-width($w-narrower)
{
    .HeaderWrapper
    {
        height: 64px;
        padding: 20px 0 16px;
        .Icon,
        .IconButton
        {
            width: 24px;
            height: 24px;
        }
    }

    .HeaderCardButton,
    .HeaderSpinner
    {
        right: 16px;
    }

    .HeaderMenuButton
    {
        left: 16px;
    }
}