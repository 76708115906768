
@import "../../../../Import/SCSS/classes";

.TemplatePressFetch
{
    @include button-behaviour;
    @include no-select;

    position: relative;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;

    @include adjust
    {
        padding: 0 0 (120px * $adj);
        font-size: 17px * $adj;
    }
    
    span
    {
        position: relative;
    }

    .Spinner
    {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &,
        .Icon
        {
            width: 1em;
            height: 1em;
        }

        @include adjust
        {
            left: calc(100% + #{8px * $adj});
        }
    }

    &.Disabled
    {
        opacity: .5;
        pointer-events: none;
    }
}

.TemplatePressItem
{
    vertical-align: top;
}

.TemplatePressItems
{
    @include items-container;

    .Loading
    {
        position: relative !important;
        color: currentColor !important;
        background-color: transparent !important;
        
        @include adjust
        {
            height: 200px * $adj;
        }
    }
}

.TemplatePressItemsWrapper
{
    @include items-wrapper;
}

.TemplatePressTop
{
    @include content-segment;

    padding-bottom: 0 !important;

    h1
    {
        @include heading-3;

        text-transform: none;
    }

    h5
    {
        @include heading-5;

        @include adjust
        {
            margin-bottom: 40px * $adj;
        }
    }
}