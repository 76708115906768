@import "../../../../Import/SCSS/classes";

.TemplateMenuNew
{
    position: relative;
}

.TemplateMenuNewBackground
{
    @include overlay;
    background-size: 100%;
    pointer-events: none;
}

.TemplateMenuNewBadge
{
    position: absolute;
    right: 0;
    bottom: 2%;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    
    @include adjust
    {
        width: 298px * $adj;
        height: 298px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        bottom: 50px;
        width: 130px;
        height: 130px;
    }

    @include responsive-width($w-narrower)
    {
        bottom: 0px;
        width: 105px;
        height: 105px;
    }
}

.TemplateMenuNewBorder
{
    position: absolute;
    top: 1.5%;
    width: 8.9%;
    bottom: 1.5%;
    background-size: 100% auto;
    pointer-events: none;
    mask-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 0.5%);

    &.Left
    {
        left: 1.5%;
    }

    &.Right
    {
        right: 1.5%;
    }

    @include responsive-width($w-narrower)
    {
        display: none;
    }
}

.TemplateMenuNewPage
{
    @include shadow;

    position: relative;
    margin: 0 auto;
    background-color: $color-white;
    font-family: $font-family-alt-1;
    text-align: center;
    overflow: hidden;
    
    @include adjust
    {
        max-width: 1100px * $adj;
    }

    &:before
    {
        display: inline-block;
        position: relative;
        width: 0;
        padding: 0 0 141.4%;
        vertical-align: top;
        content: ""
    }

    h2
    {
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        @include adjust
        {
            margin: 0.95em 0 0;
            font-size: 26px * $adj;
        }
    }

    p
    {
        line-height: 1.2;

        @include adjust
        {
            margin: 0.5em 0;
            font-size: 16px * $adj;
        }
    }

    @include responsive-width($w-narrow)
    {
        h1
        {
            @include adjust
            {
                font-size: 20px * $adj;
            }
        }

        h2, h3
        {
            @include adjust
            {
                font-size: 18px * $adj;
            }
        }

        h4, h5, h6
        {
            @include adjust
            {
                font-size: 16px * $adj;
            }
        }

        p
        {
            @include adjust
            {
                font-size: 12px * $adj;
            }
        }
    }

    @include responsive-width($w-narrower)
    {
        h2
        {
            @include adjust
            {
                font-size: 16px * $adj;
            }
        }
    }
}

.TemplateMenuNewPageContainer
{
    @include adjust
    {
        padding: 72px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        padding: 48px;
    }

    @include responsive-width($w-narrower)
    {
        padding: 40px 16px;
    }
}

.TemplateMenuNewPageContent
{
    display: inline-block;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    vertical-align: top;

    @include adjust
    {
        max-width: 1100px * $adj;
        padding: (190px * $adj) (120px * $adj) (260px * $adj);
    }

    @include responsive-width($w-narrow)
    {
        padding: (140px * $adj) (80px * $adj) (200px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: (140px * $adj) (20px * $adj) (200px * $adj);

        br
        {
            display: none;
        }
    }
}

.TemplateMenuNewPageContentBottom
{
    position: absolute;
    right: 0;
    bottom: 3.5%;
    left: 0;

    h2
    {
        user-select: none;
    }
}

.TemplateMenuNewPageContentBottomText
{
    display: inline-block;
    padding: 0.5em 2em;
    border: 1px solid currentColor;

    p
    {
        line-height: 1.6;
    }

    @include adjust
    {
        margin: 0 (20px * $adj);
    }

    @include responsive-width($w-narrower)
    {
        padding: 0.5em 1em;
        
        p
        {
            font-size: 8px;
        }
    }
}

.TemplateMenuNewPageContentTop
{
    position: absolute;
    top: 3%;
    right: 0;
    left: 0;

    .MainLogo
    {
        @include adjust
        {
            width: 280px * $adj;
        }

        @include responsive-width($w-narrow)
        {
            @include adjust
            {
                width: 200px * $adj;
            }
        }

        @include responsive-width($w-narrower)
        {
            @include adjust
            {
                width: 140px * $adj;
            }
        }
    }
}

.TemplateMenuNewPageContentTopInfo
{
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    white-space: nowrap;

    @include adjust
    {
        margin: 0.5em 0 0;
        font-size: 28px * $adj;
    }

    span
    {
        position: relative;
        padding: 0 0.6em;

        &:first-child
        {
            padding-left: 0;
        }

        &:last-child
        {
            padding-right: 0;
        }
    }

    span + span:before
    {
        display: block;
        position: absolute;
        top: 0.2em;
        bottom: 0.2em;
        left: 0;
        width: 0.1em;
        background-color: currentColor;
        content: "";
    }

    @include responsive-width($w-narrow)
    {
        @include adjust
        {
            font-size: 20px * $adj;
        }
    }

    @include responsive-width($w-narrower)
    {
        @include adjust
        {
            font-size: 16px * $adj;
        }
    }
}

.TemplateMenuNewTray
{
    position: relative;

    @include adjust
    {
        margin-top: 72px * $adj;
        padding-bottom: 72px * $adj;
    }
}