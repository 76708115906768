
@use "sass:list";

@mixin active
{
    &:active
	{
        @content;
    }
}

@mixin animation($str)
{
	animation: #{$str};
	-webkit-animation: #{$str};
}

@mixin box-sizing
{	
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

@mixin hover
{
    .Core.NoTouch &:hover
	{
        @content;
    }
}

@mixin hover-active
{
    .Core.NoTouch &:hover,
	&:active
	{
        @content;
    }
}

@mixin center($y: 50%, $x: 50%)
{
	position: absolute;
	top: $y;
	left: $x;
	transform: translate(-50%, -50%);
}

@mixin grayscale
{
	filter: grayscale(100%);
}

@mixin input-placeholder
{
	&::-webkit-input-placeholder
	{
		@content;
	}
	
	&:-moz-placeholder
	{
		@content;
	}
	
	&::-moz-placeholder
	{
		@content;
	}
	
	&:-ms-input-placeholder
	{
		@content;
	}
}

@mixin keyframes($name)
{
	@-webkit-keyframes #{$name}
	{
		@content;
	}
	
	@keyframes #{$name}
	{
		@content;
	}
}

@mixin linear-gradient($direction, $color-stops...)
{
	@if is-direction($direction) == false
	{
		$color-stops: $direction, $color-stops;
		$direction: 180deg;
	}
	
	background: nth(nth($color-stops, 1), 1);
	background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
	background: linear-gradient($direction, $color-stops);
}

@mixin max-rows($r, $l: $line-height)
{
	display: block;
	display: -webkit-box;
	height: ( $r * $l );
	line-height: $l;
	overflow: hidden;
	text-overflow: ellipsis;
	
	@include vendor-prefix(line-clamp, $r);
	@include vendor-prefix(box-orient, vertical);
}

@mixin no-select
{
	-webkit-touch-callout: none;
	@include vendor-prefix(user-select, none);
}

@mixin no-select-restore
{
	-webkit-touch-callout: initial;
	@include vendor-prefix(user-select, initial);
}

@mixin nth-child($p, $b, $i, $c: 10)
{
	@for $n from 1 through $c
	{
		&:nth-child(#{$c}n+#{$n})
		{
			#{$p}: $b + ( $n - 1 ) * $i;
		}
	}
}

@mixin overlay($b: 0, $p: absolute)
{
	position: $p !important;
	top: $b;
	right: $b;
	bottom: $b;
	left: $b;
}

@mixin responsive-width($width)
{
	@media screen and (max-width: $width)
	{
		@content;
	}
}

@mixin responsive-width-min($width)
{
	@media screen and (min-width: $width)
	{
		@content;
	}
}

@mixin responsive-height($height)
{
	@media screen and (max-height: $height)
	{
		@content;
	}
}

@mixin responsive-height-min($height)
{
	@media screen and (min-height: $height)
	{
		@content;
	}
}

@mixin responsive-width-and-height($width, $height)
{
	@media screen and (max-width: $width) and (max-height: $height)
	{
		@content;	
	}
}

@mixin responsive-width-or-height($width, $height)
{	
	@media
	screen and (max-width: $width),
	screen and (max-height: $height)
	{
		@content;
	}
}

@mixin responsive-ratio($o: 1, $u: 1)
{
	@media screen and (max-aspect-ratio: list.slash($o, $u))
	{
		@content;
	}
}

@mixin responsive-ratio-and-width($ratio, $width)
{
	@media screen and (min-aspect-ratio: $ratio) and (max-width: $width)
	{
		@content;
	}
}

@mixin responsive-ratio-and-height($ratio, $height)
{
	@media screen and (min-aspect-ratio: $ratio) and (max-height: $height)
	{
		@content;
	}
}

@mixin responsive-ratio-and-width-and-height($ratio, $width, $height)
{
	@media screen and (min-aspect-ratio: $ratio) and (max-width: $width) and (max-height: $height)
	{
		@content;
	}
}

@mixin selection
{
	::-moz-selection
	{
		@content;
	}
	
	::selection
	{
		@content;
	}
}

@mixin stagger($item, $f: 0, $t: 10)
{
	@for $i from $f through $t
	{
		$sm: $i !global;
		#{$item}:nth-child(#{$i})
		{
			@content;
		}
	}
}

@mixin text-overflow
{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin text-stroke($color)
{
	$w1: 1px;
	$w2: -1px;

	text-shadow:
	$w2 $w2 0 $color,
	$w1 $w2 0 $color,
	$w2 $w1 0 $color,
	$w1 $w1 0 $color;
}

@mixin touch
{
    .Core.Touch &
	{
        @content;
    }
}

@mixin vendor-prefix($name, $argument)
{
	#{$name}: #{$argument};
	-webkit-#{$name}: #{$argument};
	-moz-#{$name}: #{$argument};
	-ms-#{$name}: #{$argument};
	-o-#{$name}: #{$argument};
}