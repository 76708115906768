
@import "../../../Import/SCSS/classes";

.Box
{
    @include segment;

    font-weight: 500;

    @include adjust
    {
        padding: (24px * $adj) (16px * $adj) (32px * $adj);
        border-radius: 16px * $adj;
        font-size: 14px * $adj;
        line-height: 20px * $adj;
    }

    .Box + &
    {
        @include adjust
        {
            margin-top: 16px * $adj;
        }
    }
}