
@import "../../../Import/SCSS/classes";

.IconButton
{
    @include button-behaviour;
    
    display: inline-block;
    position: relative;
    vertical-align: middle;

    .Icon
    {
        display: block;
        width: 100%;
        height: 100%;
    }

    &:focus
    {
        color: $color-gold;
        outline: none;
    }
}

.IconButtonLabel
{
    position: absolute;
    top: 100%;
    left: 50%;
    font-weight: 500;
    transform: translateX(-50%);

    @include adjust
    {
        font-size: 10px * $adj;
    }
}