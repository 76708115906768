
@use "sass:math";

@import "../../../../Import/SCSS/classes";

.TemplateBooking
{
    text-align: center;

    @include adjust
    {
        padding: 0 (16px * $adj);
    }
    
    h1
    {
        @include heading-6;

        @include adjust
        {
            margin: (40px * $adj) 0 !important;
        }
    }

    h2
    {
        @include heading-3;

        @include adjust
        {
            margin: (40px * $adj) 0 0 !important;
        }

        @include responsive-width($w-narrow)
        {
            margin: 24px auto 0 !important;
        }
    }

    .TemplateBookingFormWrapper
    {
        position: relative;
        z-index: 1;

        @include adjust
        {
            max-width: $w-content * $adj;
            margin: (84px * $adj) auto 0;
        }

        .Form
        {
            z-index: 2;
        }

        @include responsive-width($w-narrow)
        {
            margin: 30px auto 0;
        }
    }
}

.TemplateBookingCatering,
.TemplateBookingExtra
{
    position: relative;
    
    @include adjust
    {
        max-width: 1343px * $adj;
        margin: (90px * $adj) auto 0;
    }

    @include responsive-width($w-narrow)
    {
        margin: 30px auto 0;
    }
}

.TemplateBookingCateringDialog
{
    h2
    {
        @include heading-5;
        text-transform: none;
    }
}

.TemplateBookingCateringDialogAbout
{
    @include content;

    @include adjust
    {
        margin-top: 40px * $adj;
    }
}

.TemplateBookingCateringItem,
.TemplateBookingExtraItem
{
    display: inline-block;
    position: relative;
    width: math.div(100%, 3);

    @include adjust
    {
        padding: 8px * $adj;
    }

    h4
    {
        @include heading-5;
        text-transform: none;
    }

    &.Selected .ProductItem:after
    {
        @include overlay;

        display: block;
        border: 2px solid $color-gold;
        pointer-events: none;
        content: "";

        @include adjust
        {
            border-radius: 20px * $adj;
        }
    }

    @include responsive-width($w-narrow)
    {
        display: block;
        width: auto;
    }
}

.TemplateBookingCateringItems,
.TemplateBookingExtraItems
{
    @include adjust
    {
        margin: -8px * $adj;
    }
}

.TemplateBookingCateringItemPrice,
.TemplateBookingCateringDialogPrice,
.TemplateBookingExtraDialogPrice,
.TemplateBookingExtraItemPrice
{
    @include field-label;

    color: $color-gray;

    @include adjust
    {
        margin: (8px * $adj) 0 0;
    }
}

.TemplateBookingItemEatery,
.TemplateBookingItemRoom
{
    white-space: nowrap;

    :last-child > &
    {
        @include adjust
        {
            margin-bottom: 8px * $adj;
        }
    }

    .Icon
    {
        display: inline-block;
        vertical-align: middle;

        @include adjust
        {
            width: 32px * $adj;
            height: 32px * $adj;
        }
    }
}

.TemplateBookingItemEateryContent,
.TemplateBookingItemRoomContent
{
    display: inline-block;
    position: relative;
    vertical-align: middle;

    @include adjust
    {
        font-size: 14px * $adj;
    }
}

.TemplateBookingItemEateryCity,
.TemplateBookingItemRoomFeatures
{
    position: absolute;
    top: 100%;
    left: 0;
    color: $color-gray-light;

    @include adjust
    {
        font-size: 12px * $adj;
        line-height: 16px * $adj;
    }

    .Selected &
    {
        color: $color-gold;
    }
}

.TemplateBookingItemRoomPeople
{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include adjust
    {
        right: 72px * $adj;
        font-size: 14px * $adj;
    }
}

.TemplateBookingLink
{
    @include button-behaviour;
    @include heading-6;

    text-decoration: underline;

    @include adjust
    {
        margin: (72px * $adj) 0;
    }

    &.Disabled
    {
        opacity: .5;
        pointer-events: none;
    }
}

.TemplateBookingReview
{
    position: relative;
    text-align: left;

    @include adjust
    {
        max-width: $w-content * $adj;
        margin: (64px * $adj) auto (72px * $adj);
    }

    h3
    {
        @include field-label;

        font-weight: 500;

        @include adjust
        {
            margin: 0 0 (40px * $adj);
        }
    }

    @include responsive-width($w-narrow)
    {
        margin: 30px auto;
    }
}

.TemplateBookingReviewAfter,
.TemplateBookingReviewBefore
{
    position: relative;
    font-style: italic;

    @include adjust
    {
        font-size: 12px * $adj;
    }
}

.TemplateBookingReviewAfter
{
    text-align: center;

    @include adjust
    {
        margin-top: 72px * $adj;
    }

    @include responsive-width($w-narrow)
    {
        margin-top: 30px;
    }
}

.TemplateBookingReviewBefore
{
    text-align: right;

    @include adjust
    {
        margin-bottom: 8px * $adj;
    }
}

.TemplateBookingReviewCell
{
    display: inline-block;
    position: relative;
    width: 40%;
    letter-spacing: .05em;
    vertical-align: top;

    &:nth-child(1)
    {
        text-transform: uppercase;
    }

    &:nth-child(3)
    {
        width: 20%;
        font-weight: 500;
        text-align: right;
    }

    &.TextCell
    {
        font-style: italic;
    }
}

.TemplateBookingReviewRow
{
    position: relative;

    @include adjust
    {
        margin: (4px * $adj) 0;
        font-size: 10px * $adj;
        line-height: 12px * $adj;
    }
}

.TemplateBookingReviewStage
{
    border-bottom: 1px solid currentColor;

    @include adjust
    {
        padding: (16px * $adj) (16px * $adj) (32px * $adj);
    }

    &:last-child
    {
        border-bottom: none;
    }
}

.TemplateBookingReviewTable
{
    position: relative;
    border: 1px solid currentColor;
}

.TemplateBookingTray
{
    @include adjust
    {
        max-width: $w-content * $adj;
        margin: (72px * $adj) auto 0;
        padding: 0 0 (72px * $adj);
    }

    .Button
    {
        display: inline-block;
        max-width: none;
        box-sizing: border-box;

        @include adjust
        {
            width: calc(50% - #{12px * $adj});
        }

        .Icon
        {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include adjust
            {
                width: 32px * $adj;
                height: 32px * $adj;
            }
        }

        .Spinner .Icon
        {
            @include adjust
            {
                width: 18px * $adj;
                height: 18px * $adj;
            }
        }

        &.Left
        {
            @include adjust
            {
                margin-right: 24px * $adj;
            }

            .Icon
            {
                left: 0;
            }
        }

        &.Right .Icon
        {
            right: 0;
        }
    }
}