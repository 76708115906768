
@import "../../../Import/SCSS/classes";

.DotNavigation
{
    @include no-select;

    position: relative;
}

.DotNavigationArrow
{
    &.Left
    {
        @include adjust
        {
            margin-right: 32px * $adj;
        }
    }

    &.Right
    {
        @include adjust
        {
            margin-left: 32px * $adj;
        }
    }
}

.DotNavigationItem
{
    @include button-behaviour;

    display: inline-block;
    position: relative;
    border: 2px solid currentColor;
    border-radius: 100%;
    box-sizing: border-box;
    vertical-align: middle;
    box-sizing: border-box;

    @include adjust
    {
        width: 12px * $adj;
        height: 12px * $adj;
        margin: 0 (6px * $adj);
    }

    &:after
    {
        @include overlay(1px);

        border-radius: 100%;
        content: "";
    }

    &:first-child
    {
        margin-left: 0;
    }

    &:last-child
    {
        margin-right: 0;
    }

    &.Active:after
    {
        background-color: currentColor;
    }
}

.DotNavigationItems
{
    display: inline-block;
    position: relative;
    line-height: 0;
    vertical-align: middle;
}