
@import "../../../Import/SCSS/classes";

.EateryNavigation
{
    position: relative;
    text-align: center;

    & > *
    {
        color: $color-white;
        background-color: $color-dark;
    }

    & > :last-child
    {
        @include adjust
        {
            border-radius: 0 0 (16px * $adj) (16px * $adj);
        }
    }

    .CollapsableView
    {
        margin-top: 1px;
    }
}

.EateryNavigationContact
{
    position: relative;

    @include adjust
    {
        margin: (32px * $adj) 0;
        font-size: 13px * $adj;
        line-height: 20px * $adj;
    }

    &:first-child
    {
        margin-top: 0 !important;
    }

    &:last-child
    {
        margin-bottom: 0 !important;
    }

    .Link
    {
        display: block;

        &:focus
        {
            text-decoration: underline;
        }
    }
}

.EateryNavigationContactLink
{
    text-decoration: underline;

    &:focus
    {
        color: $color-gold;
    }
}

.EateryNavigationExpandItem
{
    @include button-behaviour;

    @include adjust
    {
        font-size: 14px * $adj;
        line-height: 32px * $adj;
    }

    &:focus,
    &.Active
    {
        outline: none;
        text-decoration: underline;
    }

    @include hover
    {
        text-decoration: underline;
    }
}

.EateryNavigationExpandItems
{
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-dark;
    text-align: left;
    z-index: 1;
    box-sizing: border-box;

    @include adjust
    {
        width: 240px * $adj;
        padding: 32px * $adj;
    }
}

.EateryNavigationExpandItemsClose
{
    position: absolute;
    
    @include adjust
    {
        top: 20px * $adj;
        right: 20px * $adj;
        width: 16px * $adj;
        height: 16px * $adj; 
    }
}

.EateryNavigationHeader
{
    @include no-select;

    position: relative;

    @include adjust
    {
        padding: (12px * $adj) 0;
        font-size: 18px * $adj;
        line-height: 32px * $adj;
        border-radius: (16px * $adj) (16px * $adj) 0 0;
    }

    .MainLogo
    {
        height: 0.9em;
        width: auto;
        margin-left: 0.5em;

        svg
        {
            height: 100%;
            width: auto;
        }
    }
}

.EateryNavigationHeaderIcon
{
    top: -0.15em;

    @include adjust
    {
        height: 32px * $adj;
        width: 32px * $adj;
    }
}

.EateryNavigationHeaderLabel
{
    @include font-alt-2;

    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.EateryNavigationHeaderToggle
{
    @include button-behaviour;

    position: absolute !important;
    text-decoration: underline;

    @include adjust
    {
        top: 8px * $adj;
        right: 16px * $adj;
        font-size: 12px * $adj;
    }

    .SelectFieldOptionsWrapper
    {
        left: auto;
        margin-top: 0;

        @include adjust
        {
            top: -8px * $adj;
            right: -16px * $adj;
            min-width: 240px * $adj;
        }

        @include responsive-width($w-narrower)
        {
            left: 0;
        }
    }

    &:focus
    {
        color: $color-gold;
        outline: none;
    }
}

.EateryNavigationLink
{
    @include font-alt-1;
    @include no-select;

    display: block;
    position: relative;
    text-decoration: underline;

    @include adjust
    {
        padding: 0 0 (24px * $adj);
    }

    &:focus
    {
        color: $color-gold;
    }
}

.EateryNavigationMenues
{
    .Link
    {
        display: block;
        text-decoration: underline;
        
        @include adjust
        {
            font-size: 13px * $adj;
            line-height: 32px * $adj;
        }
    }
}