@import "../../../Import/SCSS/classes";

.Button
{
    @include button;

    .Spinner
    {
        $s: 16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .Icon
        {
            top: 0 !important;
        }

        @include adjust
        {
            right: $s * $adj;
            height: $s * $adj;

            .Icon
            {
                width: $s * $adj;
                height: $s * $adj;
            }
        }
    }
}