
@import "../../../Import/SCSS/classes";

.OpeningHours
{
    position: relative;
}

.OpeningHoursItem
{
    position: relative;
    text-align: left;

    @include adjust
    {
        min-height: 20px * $adj;
        margin: 0 0 (8px * $adj);
        font-size: 13px * $adj;
        line-height: 20px * $adj;
    }

    &.Deviation
    {
        color: $color-red;
    }
}

.OpeningHoursItemDays
{
    text-transform: capitalize;
}

.OpeningHoursItemDays,
.OpeningHoursItemHours
{
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.OpeningHoursItemHours
{
    @include item-column;

    position: absolute;
    right: 0;
}

.OpeningHoursNotice
{
    color: $color-gray;
    font-style: italic;
    text-transform: none;
}