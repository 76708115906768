
@use "sass:math";

@import "../../../../Import/SCSS/classes";

.TemplateActivitiesAbout
{
    @include content;
}

.TemplateActivitiesItem
{
    display: inline-block;
    position: relative;
    width: math.div(100%, 3);

    @include adjust
    {
        padding: 8px * $adj;
    }

    h4
    {
        @include heading-5;
        text-transform: none;
    }

    @include responsive-width($w-narrow)
    {
        display: block;
        width: auto;
    }
}

.TemplateActivitiesWrapper
{
    text-align: center;

    @include adjust
    {
        max-width: 1344px * $adj;
        margin: 0 auto;
        padding: 0 (16px * $adj) (160px * $adj);
    }
}

.TemplateActivitiesItems
{
    @include adjust
    {
        margin: -8px * $adj;
    }
}

.TemplateActivitiesTop
{
    @include content-segment;

    h1
    {
        @include heading-3;

        text-transform: none;
    }

    h3
    {
        @include heading-5;

        @include adjust
        {
            margin-bottom: 40px * $adj;
        }
    }
}