
@import "../../../Import/SCSS/classes";

.Form
{
    position: relative;

    @include adjust
    {
        margin: 0 (-2px * $adj);
    }

    .FieldWrapper
    {
        display: inline-block;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        vertical-align: top;

        @include adjust
        {
            margin: (8px * $adj) 0;
            padding: 0 (2px * $adj);
        }

        &.Half,
        &.HalfNarrow
        {
            width: 50%;
        }

        &.Right
        {
            .DateFieldCalendarWrapper
            {
                left: auto;
                right: 0;
            }
        }

        @include responsive-width($w-narrower)
        {
            z-index: auto !important;

            &.Half
            {
                width: 100%;
            }
        }
    }

    .FieldWrapperCheckbox
    {
        margin: 0 !important;
    }

    .FieldWrapperCheckbox + .FieldWrapperCheckbox
    {
        @include adjust
        {
            margin-top: -16px * $adj !important;
        }
    }
}