
@import "../../../../Import/SCSS/classes";

.ModuleLinks
{
    @include adjust
    {
        padding: (160px * $adj) (48px * $adj);
    }

     @include responsive-width($w-narrow)
    {
        padding: 72px 48px;
    }

    @include responsive-width($w-narrower)
    {
        padding: 32px 16px;
    }
}