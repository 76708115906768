
@import "../../../Import/SCSS/classes";

.FooterNavigation
{
    @include no-select;

    position: relative;

    & > .NavigationItems
    {
        position: relative;

        @include adjust
        {
            margin: (-24px * $adj) (-16px * $adj);
        }

        & > .NavigationItem
        {
            @include font-alt-1;

            display: inline-block;
            position: relative;
            width: 50%;
            text-transform: uppercase;
            vertical-align: top;
            box-sizing: border-box;

            @include adjust
            {
                padding: (24px * $adj) (16px * $adj);
                font-size: 17px * $adj;
            }

            .Icon
            {
                display: none;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                transition: $duration-quick;

                @include adjust
                {
                    width: 32px * $adj;
                    height: 32px * $adj;
                }
            }

            & > .NavigationItemLink
            {
                position: relative;
                letter-spacing: 0.075em;
            }

            @include responsive-width($w-narrower)
            {
                font-size: 14px * $adj;
            }
        }
    }

    .NavigationChildren
    {
        position: relative;

        @include adjust
        {
            padding-top: 24px * $adj;
        }

        .NavigationItem
        {
            text-transform: none;

            @include adjust
            {
                font-size: 17px * $adj;
                line-height: 32px * $adj;
            }
        }
    }

    .NavigationItemLink
    {
        @include button-behaviour;
        
        white-space: nowrap;

        &:focus,
        &.ActiveAncestor,
        &.ActiveLink
        {
            text-decoration: underline;
            outline: none;
        }
    }

    @include responsive-width-min($w-narrow + 1px)
    {
        .NavigationChildrenWrapper
        {
            height: auto !important;

            .CollapsableContent
            {
                opacity: 1 !important;
            }
        }
    }

    @include responsive-width($w-narrow)
    {
        & > .NavigationItems
        {
            margin: 0;
            
            & > .NavigationItem
            {
                display: block;
                width: auto;
                margin: 0 0 -1px;
                padding: 24px 50px;
                border-top: 1px solid $color-white;
                border-bottom: 1px solid $color-white;

                .Icon
                {
                    display: block;
                }

                &.Expand
                {
                    .Icon
                    {
                        transform: translateY(-50%) rotate(45deg);
                    }

                    & > .NavigationChildren
                    {
                        display: block;
                    }
                }
            }
        }
    }

    @include responsive-width($w-narrower)
    {
        & > .NavigationItems
        {
           & > .NavigationItem
            {
                padding: 24px 16px;
            }
        }
    }
}